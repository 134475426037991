import React, { Component } from 'react';
import Bg from '../assets/bg/bg.png';
import Bg_webp from '../assets/bg/bg.png.webp';
import checked from '../assets/checked-success.svg';
// COMPONENTS
import ShortForm from '../form/shortForm';
import LongForm from '../form/longForm';
import CustomRequest from '../CustomRequest';

class MainHeader extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      typeAccueil: null,
      showResponseMsg: {
        isSuccess: false,
        message: {
          title: 'Merci !',
          subtitle: 'Nous avons bien pris en compte votre demande.',
          text:
            'Un de nos conseillers prendra soin de vous contacter dans les plus brefs délais pour vous apporter une réponse personnalisée.',
        },
      },
    };
  }

  onSubmit(dataToSend, form_type = 'short') {
    let url = `request`;
    if (form_type !== 'short') {
      url = `request_` + form_type;
    }
    let customHeader = {
      method: 'POST',
      body: JSON.stringify(dataToSend),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };
    let request = CustomRequest(url, customHeader);
    fetch(request).then((r) => {
      this.setState({
        ...this.state,
        typeAccueil: dataToSend.hostType,
        showResponseMsg: {
          ...this.state.showResponseMsg,
          isSuccess: true,
        },
      });

      this.gtmDatalayer(this.props.formType);
    });
  }
  gtmDatalayer(formType) {
    if (formType === 'short') {
      this.props.dataLayer['push']({
        event: 'confirmation_success_landing',
        url_confirmation_landing: '/confirmation-contact-lp-geoloc.html',
        type_sejour: 'generique',
      });
    } else {
      let objectToSend = {
        event: 'confirmation_success_landing',
        url_confirmation_landing: '/confirmation-contact-lp-geoloc.html',
        type_sejour:
          this.state.typeAccueil.name === 'Court séjour'
            ? 'Court'
            : 'generique', // 'generique' ou 'court' : Si l'option 'court' a été sélectionnée alors, tu envoies court, si c'est une autre option, c'est generique
      };
      this.props.dataLayer['push'](objectToSend);
    }
  }

  render() {
    const { searchPlace } = this.props;
    return (
      <div className='header' id='header'>
        <div className='left--bx'>
          <div className='header-text'>
            <h2>Recherchez votre maison</h2>
            <h2>de retraite Korian {searchPlace !== null ? ':' : false}</h2>
            {searchPlace !== null ? <h2>{searchPlace}</h2> : false}
          </div>
          <img srcSet={Bg_webp} src={Bg} alt='bg-header' />
        </div>
        <div className='right--bx lg-form'>
          <div className='form---bx lg-form'>
            {this.state.showResponseMsg.isSuccess === false ? (
              <React.Fragment>
                <div className='header-form'>
                  <span className='form-main-title'>VOUS RECHERCHEZ</span>
                  <span className='form-main-title form-main-title-sec'>UNE MAISON DE RETRAITE&nbsp;?</span>
                  <span>Un conseiller vous accompagne</span>
                  <span>pour trouver une solution sous 48h</span>
                </div>
                {this.props.formType === 'short' ? (
                  <ShortForm
                    handleSubmit={(dataToSend) => {
                      this.onSubmit(dataToSend);
                    }}
                  />
                ) : (
                  <LongForm
                    handleSubmit={(dataToSend) => {
                      this.onSubmit(dataToSend, 'long');
                    }}
                  />
                )}
              </React.Fragment>
            ) : (
              <div className='header-form'>
                <div className='success-content'>
                  <img src={checked} className='success-icon' alt='bg-header' />
                  <h1>{this.state.showResponseMsg.message.title}</h1>
                  <h2>{this.state.showResponseMsg.message.subtitle}</h2>
                  <span>{this.state.showResponseMsg.message.text}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MainHeader;
