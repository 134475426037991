import L from 'leaflet';
import IconCenter from '../assets/markers/person_pin-hover.svg';
import IconPosition from '../assets/markers/person_pin_position.svg';
import Icon from '../assets/markers/person_pin.svg';

export const iconCenter = new L.Icon({
  iconUrl: IconCenter,
  iconRetinaUrl: IconCenter,
  iconSize: new L.Point(31, 45),
  className: 'leaflet-div-icon orange',
});

export const iconPosition = new L.Icon({
  iconUrl: IconPosition,
  iconRetinaUrl: IconPosition,
  iconSize: new L.Point(31, 45),
  className: 'leaflet-div-icon',
});

export const iconDefault = new L.Icon({
  iconUrl: Icon,
  iconRetinaUrl: Icon,
  iconSize: new L.Point(31, 45),
  className: 'leaflet-div-icon',
});

export const clusterDefaultIcon = function (cluster) {
  return L.divIcon({
    html: `<div class="cluster--box"><img src="${Icon}"/> <span>${cluster.getChildCount()}</span></div>`,
    className: 'marker-cluster-custom',
    iconSize: L.point(30, 30, true),
  });
};

export const clusterHoverIcon = function (cluster) {
  return L.divIcon({
    html: `<div class="cluster--box"><img src="${IconCenter}"/> <span>${cluster.getChildCount()}</span></div>`,
    className: 'marker-cluster-custom',
    iconSize: L.point(30, 30, true),
  });
};
