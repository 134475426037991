import React, { Component } from 'react';
import logo from '../assets/logo.png';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Button } from '@material-ui/core';
// COMPONENTS

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

class Footer extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      show: false
    };
  }

  render() {
    const { show } = this.state;
    return (
      <div className='footer--bx'>
        <div className='logo--bx'>
          <img src={logo} alt='logo-korian' />
          <div className='info-text'>
            <span>MAISON DE RETRAITE</span>
            <span>médicalisée</span>
          </div>
        </div>
        <div className='sub-info--txt'>
          <a
            style={{ cursor: 'pointer' }}
            onClick={() => {
              this.setState({
                ...this.state,
                show: !this.state.show
              });
            }}
          >
            Mentions légales
          </a>
          <a>Rendez-vous sur www.korian.fr</a>
        </div>

        <Dialog
          open={show}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            this.setState({
              ...this.state,
              show: !this.state.show
            });
          }}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          {/* <DialogTitle id='alert-dialog-slide-title'>
            {"Use Google's location service?"}
          </DialogTitle> */}
          <DialogContent>
            {/* <DialogContentText id='alert-dialog-slide-description'> */}
            <article>
              <h2>MENTIONS LEGALES ET CONDITIONS GENERALES D’UTILISATION</h2>
              <p className="bold-text">
                En utilisant le présent site, alzheimer.korian.fr, ci-après désigné le «&nbsp;Site&nbsp;»,
                vous reconnaissez avoir pris connaissance des informations et conditions générales d’utilisation
                détaillées ci-après et vous engagez à les respecter.
              </p>
              <p>
                Ces conditions générales ont notamment pour objet de vous informer du contenu du Site, de l'existence
                de droits de propriété intellectuelle devant être respectés et des responsabilités afférentes à
                l’utilisation dudit site, ci-après désignées les «&nbsp;Conditions générales d’utilisation&nbsp;».
              </p>
              <p>
                Le contenu des présentes mentions légales et Conditions générales peut être amené à changer ;
                nous vous invitons par conséquent à les consulter régulièrement.
              </p>
              <br/>
              <h2>1. MENTIONS LEGALES</h2>
              <p>
                <strong>Editeur et Concepteur du Site :  Clariane France</strong>, société par actions simplifiée,
                dont le siège social est situé 21-25 rue Balzac, 75008 Paris, immatriculée au registre du commerce
                et des sociétés de Paris sous le n° 892 318 882 et dont le numéro de TVA intracommunautaire
                est le FR31892318882.
              </p>
              <p><strong>Directeur de la publication du Site : Nicolas Merigot, Président</strong></p>
              <p>
                Toute personne peut joindre l’Editeur du Site à l’adresse électronique
                <a href="mailto:infoconseils@clariane.fr"> infoconseils@korian.fr</a> ou par téléphone au 01 55 37 52 00.
              </p>
              <p>
                <strong>Hébergeur du Site</strong> : Société OVH, enregistrée au RCS de Lille Métropole sous le numéro
                424 761 419 00045, dont le siège social est le 2 rue Kellermann - 59100 Roubaix - France.
              </p>
              <p>Toute personne peut joindre l’Hébergeur du Site au numéro de téléphone suivant : 1007.</p>
              <p>
                <strong>Infogérance du Site </strong>: CLARANET enregistrée au RCS de Paris sous numéro B 419 632 286, dont le siège
                social est 2, rue Bréguet 75011 Paris.
              </p>
              <p>
                Conformément à l’article L6122-1 du Code de la Sante publique et à l’article L313-1 et suivants du
                Code de l’action sociale et des familles, en tant qu’établissement médico-social, chaque
                établissement dispose des autorisations nécessaires à l’exercice de son activité
                et délivrées par l’agence régionale de santé dédiée et/ou le conseil départemental concerné.
              </p>
              <br/>
              <h2>2. OBJET DU SITE</h2>
              <p>
                Le Site met à disposition des informations à destination des personnes souhaitant se renseigner sur
                l’activité d’établissements d’hébergement pour personnes âgées dépendantes de Clariane France et les
                engagements de la société Clariane France.
              </p>
              <br/>
              <h3>2.1. Newsletter</h3>
              <p>Le Site permet également aux personnes utilisatrices de s’abonner à une newsletter les informant notamment sur l’actualité du Site et celle des établissements d’hébergement pour personnes âgées dépendantes de la société Clariane France</p>
              <p>La newsletter a également vocation à mieux connaître le groupe Clariane.</p>
              <br/>
              <h3>2.2. Formulaire de contact</h3>
              <p>
                Le Site propose un formulaire de contact à destination des personnes souhaitant être contactées par
                Clariane France afin d’obtenir de plus amples informations sur ses établissements d’hébergement pour
                personnes âgées dépendantes.
              </p>
              <p>Vous êtes seul responsable de l’ensemble du contenu que vous adressez dans le cadre de l’envoi de ce formulaire.   </p>
              <p>
                Vous vous engagez dans ce cadre à :
                <ul>
                  <li>
                    Être le propriétaire unique et exclusif de l’ensemble du contenu que vous adressez via le
                    formulaire, soit que vous disposez des autorisations nécessaires pour effectuer une telle demande ;
                  </li>
                  <li>
                    Ne pas enfreindre, détourner ni violer aucun droit de confidentialité de tiers, ni enfreindre
                    le secret médical, ni entraîner la violation de toute loi ou règlement applicable ;
                  </li>
                  <li>Ne pas joindre de données de santé à votre demande.</li>
                </ul>
              </p>
              <br/>
              <h3>2.3. Témoignage</h3>
              <p>
                Le Site permet de pouvoir déposer ou prendre connaissance de témoignages de résidents, de familles de
                résidents ou de salariés de Clariane France.
              </p>
              <p>
                Une modération des témoignages peut être mise en œuvre par Clariane France et a pour but
                de s’assurer de la conformité du contenu aux législations et réglementations en vigueur et aux présentes
                dispositions. Cette modération peut entrainer la publication, le rejet ou la suppression du contenu.
              </p>
              <p>
                La modération est réalisée par des outils informatiques et/ou des personnes humaines désignées en
                qualité de modérateurs.
              </p>
              <p>
                Ces derniers sont habilités à :
                <ul>
                  <li>Accéder à l’intégralité du contenu posté ;</li>
                  <li>Accéder aux éléments relatifs à l’identité de l’auteur d’un témoignage.</li>
                </ul>
              </p>
              <p>
                Une modération peut intervenir a priori en tendant à éviter la publication d’un contenu potentiellement
                illicite, inintelligible, diffamant, dénigrant, injurieux, grossier, obscène, discriminatoire,
                menaçant ou qui violerait les lois en vigueur. Les témoignages suivants ne seront donc pas publiés :
                <ul>
                  <li>Le contenu est sans rapport avec le sujet du témoignage ;</li>
                  <li>
                    Le contenu textuel comporte des informations personnelles, sensibles ou toute autre information
                    susceptible d'aboutir à un vol d'identité comme, sans que cette liste ne soit exhaustive,
                    le nom ou le prénom d'individus qui ne sont pas des personnes publiques, un numéro de téléphone,
                    une adresse physique précise ou une adresse email, un numéro de carte de crédit, de sécurité
                    sociale, de compte bancaire ou tout autre information susceptible d'aboutir à un vol d'identité ;
                  </li>
                  <li>
                    Si la responsabilité civile ou pénale de l’éditeur peut être engagée au titre du contenu
                    d’un témoignage ;
                  </li>
                  <li>Le témoignage comporte des éléments concrets de conflits d'intérêts ;</li>
                  <li>Témoignage inapproprié sur un autre service ou une personne identifiée ;</li>
                  <li>Le contenu textuel comporte un appel à une action en justice.</li>
                </ul>
              </p>
              <p>Le contenu textuel d’un témoignage doit être en langue française.</p>
              <p>
                <strong>Clariane France</strong> se réserve la possibilité, de plein droit, de ne pas publier, de modérer,
                d'éditer ou de supprimer les contenus enfreignant cette règle, en particulier ceux qu'elles jugeraient
                insultant à son égard, diffamatoires à l'encontre de ses établissements ou partenaires, et qui
                pourraient porter atteinte à son image ou celle de ses partenaires. Vous ne pourrez soulever aucune
                réclamation à ce titre.
              </p>
              <p>
                Enfin, <strong>Clariane France</strong> se réserve la possibilité :
                <ul>
                  <li>De corriger les fautes d’orthographe contenues dans un témoignage ;</li>
                  <li>De supprimer les données permettant l’identification du témoin.</li>
                </ul>
              </p>
              <br/>
              <h2>3. DONNEES PERSONNELLES</h2>
              <p>
                Vous trouverez la notice d'information complète, relative aux données personnelles traitées au sein
                du présent Site, dans le volet Protection des données personnelles en bas de page dudit Site.
              </p>
              <br/>
              <h2>4. PROPRIÉTÉ INTELLECTUELLE</h2>
              <h3>4.1. Contenu protégé</h3>
              <p>
                Vous reconnaissez que l’ensemble du Site, sa charte graphique ainsi que l’ensemble de son contenu,
                incluant, sans que cette liste ne soit limitative, l’architecture du Site, tous textes, articles,
                informations, photographies, vidéos, images, illustrations, des données à caractère personnel,
                logiciels, marques, logos contenus dans le Site, (ci-après le «&nbps;Contenu&nbps;») sont protégés par
                le droit de la propriété intellectuelle et sont la propriété pleine et entière de
                Clariane France ou des titulaires des droits ayant accordés une licence à Clariane France.
              </p>
              <br/>
              <h3>4.2. Activités interdites et autorisées </h3>
              <p>
                Dans le cadre de votre utilisation du Site, il vous est interdit :
                <ul>
                  <li>
                    D’utiliser, reproduire, adapter, modifier, créer des œuvres dérivées, distribuer, concéder une
                    licence, vendre, diffuser ou exploiter de toute autre manière le Contenu du Site, aucune licence,
                    ni aucun droit n’étant concédé implicitement ou autrement par les présentes ;
                  </li>
                  <li>
                    D’enfreindre ou contourner quelconques lois ou règlements applicables, ou accords passés avec des
                    tiers, droits de tiers ou encore les présentes dispositions ;
                  </li>
                  <li>
                    D’utiliser ou réutiliser le Site ou son Contenu à des fins commerciales ou non ;
                  </li>
                  <li>
                    Éviter, détourner, retirer, désactiver, endommager, décoder, ou tenter de contourner d’une autre
                    manière toute mesure technologique mise en place par Clariane France ou tout
                    intervenant technique ;
                  </li>
                  <li>
                    De tenter de déchiffrer, décompiler, désassembler ou rétro-concevoir un quelconque logiciel
                    utilisé pour fournir le Site ;
                  </li>
                  <li>
                    Prendre une quelconque mesure qui perturbe ou nuit à, ou pourrait perturber ou nuire à la
                    performance ou au bon fonctionnement du Site ;
                  </li>
                  <li>
                    De mettre en place tous liens hypertextes vers tout ou partie du Site, sauf autorisation préalable
                    et écrite de Clariane France. Un tel lien devra être immédiatement retiré sur simple demande
                    de Clariane France ;
                  </li>
                  <li>
                    D’extraire même temporairement une partie ou le contenu d’une base de données présente sur le Site ;
                  </li>
                  <li>
                    De reproduire, stocker, accéder à ou utiliser quelconques informations, y compris des informations
                    nominatives concernant par exemple l’auteur d’un témoignage/avis, accessible sur ou depuis le Site
                    ou de porter atteinte aux droits au respect de la vie privée de ces personnes.
                  </li>
                </ul>
              </p>
              <p>
                La loi n’autorise que les « copies ou reproductions strictement réservées à l’usage privé du copiste et
                non destinées à une utilisation collective » et que les analyses et les courtes citations uniquement
                dans un but d’exemple et d’illustration.
              </p>
              <p>
                Toute représentation ou reproduction du Site en dehors du cadre précisé ci-avant, par quelque procédure
                que ce soit, pourrait notamment constituer une contrefaçon sanctionnée par le Code pénal.
              </p>
              <br/>
              <h2>5. PHOTOGRAPHIES PRÉSENTES SUR LE SITE</h2>
              <p>
                Clariane France vous informe que les photographies publiées sur le Site sont pour certaines
                présentes à titre purement illustratif et n’ont pas vocation à représenter de manière spécifique ce à
                quoi elles sont rattachées à savoir par exemple les personnes séjournant dans les établissements
                Clariane France ou des personnes atteintes d’une maladie particulière.
              </p>
              <br/>
              <h2>6. RESPONSABILITÉS</h2>
              <p>
                Vous consulter le Site sous votre seule responsabilité. Clariane France n’est pas
                responsable des dommages directs ou indirects résultant de l’accès ou de l’utilisation du Site ou de
                tout site liés.
              </p>
              <br/>
              <h3>6.1. Responsabilité relative aux informations publiées sur le Site</h3>
              <p>
                Clariane France s’efforce d’apporter le plus grand soin à la sélection et à la diffusion
                des informations et contenus proposés sur son Site. Clariane France ne peut toutefois
                garantir de manière certaine l’exactitude, l’exhaustivité ou la mise à jour constante desdits éléments.
              </p>
              <p>
                Les informations relevant du domaine médical sont uniquement destinées à l’information de l’utilisateur.
                Ces dernières ne sauraient remplacer l’avis d’un spécialiste dans le domaine.
              </p>
              <p>
                Clariane France décline ainsi toute responsabilité en cas d'erreur, d'inexactitude ou
                d'omission dans ces informations et contenus. De même, Clariane France, n’est soumise qu’à
                une obligation de moyen et ne saurait être tenue pour responsable de tout préjudice direct ou indirect
                du fait d'une information mal utilisée et/ou qui se serait révélée inexacte ou incomplète, sans que
                cette liste soit exhaustive.
              </p>
              <br/>
              <h3>6.2. Responsabilité relative à des sites tiers</h3>
              <p>
                Le Site peut contenir des liens vers d’autres sites internet pour lesquels Clariane France
                décline toute responsabilité quant à leur contenu, leurs pratiques ainsi que les dommages en découlant.
              </p>
              <p>
                Ces liens ne constituent en aucune manière une approbation, une validation ou une adhésion de vau
                contenu de ces sites, ni une association de Clariane France avec les propriétaires et/ou
                les auteurs, concepteurs, animateurs, gestionnaires ou hébergeurs desdits sites. Ces liens sont fournis
                à simple titre d’information et la décision de consulter ces Sites relève de votre pleine et entière
                responsabilité.
              </p>
              <br/>
              <h3>6.3. Responsabilités techniques</h3>
              <p>
                Vous reconnaissez que :
                <ul>
                  <li>
                    vous connaissez les caractéristiques et des limites de l'Internet et des technologies liées à
                    l’utilisation du Site ;
                  </li>
                  <li>
                    votre matériel de connexion, nécessaire pour l’utilisation du Site, est sous votre entière
                    responsabilité ;
                  </li>
                  <li>
                    vous prenez toutes les mesures appropriées visant à protéger vos propres données contre notamment
                    des détournements éventuels ou piratage et risques de contamination par des éventuels virus
                    circulants sur internet.
                  </li>
                </ul>
              </p>
              <p>
                Clariane France ne saurait être tenue responsable d’interruption de liaison, de pannes de
                serveurs, de problèmes électriques ou autres difficultés liées au réseau informatique Internet,
                notamment en cas de défaillance, panne ou interruption de fonctionnement, empêchant l'accès du Site.
              </p>
              <p>
                Tous les logiciels et matériels nécessaires à l’utilisation ou et/ou au fonctionnement du Site, l’accès
                à Internet ou les frais de connexion sont à votre charge exclusive d’utilisateur. Vous êtes seul
                responsable du bon fonctionnement de votre équipement informatique et de votre accès Internet.
              </p>
              <br/>
              <h2>7. DISPOSITIONS GÉNÉRALES</h2>
              <p>
                Clariane France peut, à tout moment et sans préavis, apporter des améliorations, des
                corrections ou des changements aux informations et contenus proposés sur le Site et de fait en
                interrompre l’accessibilité en tout ou partie.
              </p>
              <p>
                Si une partie des Conditions générales d’utilisation devait s'avérer invalide ou inapplicable, pour
                quelque raison que ce soit, les dispositions en question seraient réputées non écrites, sans remettre
                en cause la validité des autres dispositions qui continueront de s'appliquer aux utilisateurs du Site.
              </p>
              <p>Les présentes Conditions générales d’utilisation sont soumises à la loi française.</p>
              <p>
                En cas de litige relatif à l'exécution ou à l'interprétation des présentes Conditions générales
                d'utilisation, après l’échec de toute tentative de recherche d’une solution amiable,
                les tribunaux français seront seuls compétents.
              </p>
            </article>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  ...this.state,
                  show: !this.state.show
                });
              }}
            >
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Footer;
