import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import checked from './../assets/checked.png';
import error from './../assets/error-icon.svg';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { MdMyLocation, MdClose, MdLocationOn } from 'react-icons/md';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { DebounceInput } from 'react-debounce-input';
import CustomRequest from '../CustomRequest';

class ShortForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.source = 'places?autocomplete=true';
    this.entity = 'place';
    this.state = {
      gender: {
        value: null,
        isSuccess: false,
      },
      name: {
        value: null,
        isSuccess: false,
      },
      phone: {
        value: null,
        isSuccess: false,
      },
      email: {
        value: null,
        isSuccess: false,
      },
      ville: {
        value: null,
        isSuccess: false,
      },
      newsletter: false,
      commercial: false,
      selectedCity: null,
      urgent: false,
      searchResultat: [],
      loading: false,
      disabled: true,
      showList: false,
    };
  }

  checkGender() {
    let element = document.querySelector('[name=gender]:checked');
    if (!this.state.gender.isSuccess && element !== null) {
      this.setState({
        ...this.state,
        gender: {
          value: element.value,
          isSuccess: true,
        },
      });
    }

    // vérifions si nous sommes en mobile ou desktop
    let mobileGender = document.querySelector('.form-control.w-100.mobile');
    if (mobileGender.offsetParent === null) {
      // Mode desktop
      // Modifier les classes pour afficher la bordure
      let fstDiv = document.querySelector('.fst-input--radio-bx');
      let sndDiv = document.querySelector('.snd-input--radio-bx');
      setTimeout(() => {
        if (this.state.gender.isSuccess) {
          fstDiv.setAttribute('class', 'fst-input--radio-bx success');
          sndDiv.setAttribute('class', 'snd-input--radio-bx success');
        } else {
          fstDiv.setAttribute('class', 'fst-input--radio-bx error');
          sndDiv.setAttribute('class', 'snd-input--radio-bx error');
        }
      }, 10);

      // afficher l'icone
      let validationIcon = document.querySelector(
        '.snd-input--radio-bx ~ .text--input-validation'
      );
      validationIcon.setAttribute('style', 'z-index:0');
    } else {
      // Mode mobile
      // Modifier les classes pour afficher la bordure

      let fstDiv = document.querySelector('.fst-input--radio-bx.mobile');
      let sndDiv = document.querySelector('.snd-input--radio-bx.mobile');
      if (this.state.gender.isSuccess) {
        fstDiv.setAttribute('class', 'fst-input--radio-bx success');
        sndDiv.setAttribute('class', 'snd-input--radio-bx success');
      } else {
        fstDiv.setAttribute('class', 'fst-input--radio-bx error');
        sndDiv.setAttribute('class', 'snd-input--radio-bx error');
      }

      // afficher l'icone success
      let validationIcon = document.querySelector(
        '.form-control.mobile > .text--input-validation'
      );
      // let validationIcon = document.querySelector('.validation-icon.mobile');
      validationIcon.setAttribute('style', 'z-index:0');
    }
  }

  checkName(value) {
    if (
      value &&
      value.length > 2
      // ||
      // (this.state.name.value && this.state.name.value.length > 2)
    ) {
      this.setState({
        ...this.state,
        name: {
          value: value,
          isSuccess: true,
        },
      });
      // Modifier la classes pour afficher la bordure success
      let name = document.querySelector('#lastname.text--input');
      name.setAttribute('class', 'text--input success');
      // afficher l'icone success
      let validationIcon = document.querySelector('.text--input-validation');
      validationIcon.setAttribute('style', 'z-index:0');
    } else {
      this.setState({
        ...this.state,
        name: {
          value: value,
          isSuccess: false,
        },
      });
      // Modifier la classes pour afficher la bordure erreur
      let name = document.querySelector('#lastname.text--input');
      name.setAttribute('class', 'text--input error');
      // afficher l'icone error
      let validationIcon = document.querySelector(
        '#lastname ~ .text--input-validation'
      );
      validationIcon.setAttribute('style', 'z-index:0');
    }

    // Check Ville field
    // this.checkVilleValue();
  }

  checkPhone(value) {
    if (
      /[0-9]{10}/.test(value) &&
      value.length === 10 &&
      (value[0] === '0' || value[0] === 0)
    ) {
      this.setState({
        ...this.state,
        phone: {
          value: value,
          isSuccess: true,
        },
      });
      // Modifier la classes pour afficher la bordure success
      let phone = document.querySelector('#phone.text--input');
      phone.setAttribute('class', 'text--input success');
      // afficher l'icone success
      let validationIcon = document.querySelector(
        '#phone ~ .text--input-validation'
      );
      validationIcon.setAttribute('style', 'z-index:0');
    } else {
      this.setState({
        ...this.state,
        phone: {
          value: value,
          isSuccess: false,
        },
      });
      // Modifier la classes pour afficher la bordure erreur
      let phone = document.querySelector('#phone.text--input');
      phone.setAttribute('class', 'text--input error');
      // afficher l'icone error
      let validationIcon = document.querySelector(
        '#phone ~ .text--input-validation'
      );
      validationIcon.setAttribute('style', 'z-index:0');
    }
  }

  checkEmail(value) {
    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (value && regex.test(value.toLowerCase())) {
      this.setState({
        ...this.state,
        email: {
          value: value,
          isSuccess: true,
        },
      });
      // vérifions si nous sommes en mobile ou desktop
      let mobilEmail = document.querySelector('.form-control.w-100.mobile');
      if (mobilEmail.offsetParent === null) {
        // Modifier la classes pour afficher la bordure success
        let email = document.querySelector('#email.text--input');
        email.setAttribute('class', 'text--input success');
        // afficher l'icone success
        let validationIcon = document.querySelector(
          '#email ~ .text--input-validation'
        );
        validationIcon.setAttribute('style', 'z-index:0');
      } else {
        // Modifier la classes pour afficher la bordure success
        let email = document.querySelector('#email.text--input.mobile-input');
        email.setAttribute('class', 'text--input mobile-input success');
        // afficher l'icone success
        let validationIcon = document.querySelector(
          '#email ~ .text--input-validation.mobile-input'
        );
        validationIcon.setAttribute('style', 'z-index:0');
      }
    } else {
      this.setState({
        ...this.state,
        email: {
          value: value,
          isSuccess: false,
        },
      }); // vérifions si nous sommes en mobile ou desktop
      let mobilEmail = document.querySelector('.form-control.w-100.mobile');
      if (mobilEmail.offsetParent === null) {
        // Modifier la classes pour afficher la bordure erreur
        let email = document.querySelector('#email.text--input');
        email.setAttribute('class', 'text--input error');
        // afficher l'icone error
        let validationIcon = document.querySelector(
          '#email ~ .text--input-validation'
        );
        validationIcon.setAttribute('style', 'z-index:0');
      } else {
        // Modifier la classes pour afficher la bordure erreur
        let email = document.querySelector('#email.text--input.mobile-input');
        email.setAttribute('class', 'text--input mobile-input error');
        // afficher l'icone error
        let validationIcon = document.querySelector(
          '#email ~ .text--input-validation.mobile-input'
        );
        validationIcon.setAttribute('style', 'z-index:0');
      }
    }
    // Check Ville field
    // this.checkVilleValue();
  }

  handleChange(value) {
    // : document.querySelector('.text--input[name="city"]').value;
    if (value === '') {
      // Déclenche le loader
      this.setState({
        ...this.state,
        loading: true,
      });
      // Stop le loader 800 ms après
      setTimeout(() => {
        this.setState({
          ...this.state,
          loading: false,
          searchResultat: [],
        });
      }, 800);
    }
    if (value !== undefined && value.length >= 2) {
      this.setState({
        ...this.state,
        loading: true,
        ville: {
          ...this.state.ville,
          isSuccess: false,
        },
      });
      // Modifions la bordure avec la couleur error
      let input = document.querySelector('.text--input[name="city"]');
      input.setAttribute('class', 'text--input error');
      // afficher l'icone error
      let validationIcon = document.querySelector(
        '.search-city--bx ~ .text--input-validation'
      );
      validationIcon.setAttribute('style', 'z-index:0');

      if (value !== this.state.selectedCity) {
        // Lançons la requête
        let url = this.source + '&searchName=' + value + '&type=VIL';
        let customHeader = {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        };
        let db_request = CustomRequest(url,
          customHeader
        );
        fetch(db_request)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            let places = data['hydra:member'];
            let places_to_show = [];
            places.map((place) => {
              places_to_show.push({
                id: place.id,
                name: place.name,
                latitude: place.latitude,
                longitude: place.longitude,
                code: place.code,
                zipcode: place.zipcode,
                searchName: place.searchName,
                type: place.type,
              });
            });
            this.setState({
              ...this.state,
              loading: false,
              showList: true,
              ville: {
                ...this.state.ville,
                value: value,
                isSuccess: false,
              },
              searchResultat: places_to_show,
            });
          });
      } else if (this.state.ville.value !== this.state.selectedCity) {
        this.selectCity(this.state.selectedCity);
      }
    }
    let input = document.querySelector('.text--input[name="city"]');
    if (value.length === 0) {
      // Modifions la bordure avec la couleur error
      input.setAttribute('class', 'text--input error');
      // afficher l'icone error
      let validationIcon = document.querySelector(
        '.search-city--bx ~ .text--input-validation'
      );
      validationIcon.setAttribute('style', 'z-index:0');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.gender.isSuccess !== this.state.gender.isSuccess ||
      prevState.name.isSuccess !== this.state.name.isSuccess ||
      prevState.phone.isSuccess !== this.state.phone.isSuccess ||
      prevState.email.isSuccess !== this.state.email.isSuccess ||
      prevState.ville.isSuccess !== this.state.ville.isSuccess
    ) {
      this.checkAllFields();
    }
  }

  checkAllFields() {
    let nbError = 0;
    if (this.state.gender.isSuccess === false) {
      nbError++;
    }
    if (this.state.name.isSuccess === false) {
      nbError++;
    }
    if (this.state.phone.isSuccess === false) {
      nbError++;
    }
    if (this.state.email.isSuccess === false) {
      nbError++;
    }
    if (this.state.ville.isSuccess === false) {
      nbError++;
    }

    if (nbError === 0) {
      this.setState({
        ...this.state,
        isValide: true,
      });
    } else {
      this.setState({
        ...this.state,
        isValide: false,
      });
    }
  }

  selectCity(value) {
    this.setState({
      ...this.state,
      showList: false,
      loading: false,
      searchResultat: [],
      selectedCity: value,
      ville: {
        value: value,
        isSuccess: true,
      },
    });
    setTimeout(() => {
      let input = document.querySelector('.text--input[name="city"]');
      // modifier la classe et afficher la bordure success
      input.setAttribute('class', 'text--input success');
      // afficher l'icone success
      let validationIcon = document.querySelector(
        '.search-city--bx ~ .text--input-validation'
      );
      validationIcon.setAttribute('style', 'z-index:0');
    }, 1);
  }

  checkVilleValue() {
    let input = document.querySelector('.text--input[name="city"]');
    if (input.value !== this.state.ville.value) {
      input.value = this.state.ville.value;
    }
  }

  onSubmit() {
    this.checkAllFields();
    if (this.state.isValide === true) {
      // Préparons les données du formulaire
      let firstname = this.state.name.value.split(' ');
      firstname = firstname.length > 1 ? firstname.pop() : 'neant';
      firstname =
        typeof firstname === 'array' ? firstname.join(' ') : firstname;

      let dataToSend = {
        gender:
          this.state.gender.value === 1 || this.state.gender.value === '1'
            ? true
            : false,
        lastname: this.state.name.value.split(' ')[0],
        firstname: firstname,
        email: this.state.email.value,
        phone: this.state.phone.value,
        city: this.state.ville.value,
        urgent: this.state.urgent,
        newsletter: this.state.newsletter,
        commercial: this.state.commercial,
        referer: window.location.search,
      };
      this.props.handleSubmit(dataToSend);
    } else {
      this.checkGender();
      let name = document.querySelector('#lastname.text--input');
      this.checkName(name.value);
      let phone = document.querySelector('#phone.text--input');
      this.checkPhone(phone.value);
      // Vérifions si nous sommes en mode desktop ou mobile
      let mobilEmail = document.querySelector('.form-control.w-100.mobile');
      let email = null;
      if (mobilEmail.offsetParent === null) {
        // email desktop
        email = document.querySelector('#email.text--input');
      } else {
        // email mobile
        email = document.querySelector('#email.text--input.mobile-input');
      }
      this.checkEmail(email.value);
      let ville = document.querySelector('.text--input[name="city"]').value;
      this.handleChange(ville);
    }
  }

  render() {
    return (
      <form id='shortForm' className='form--content'>
        <div className='form-control w-50 desktop'>
          <div className='fst-input--radio-bx'>
            <input
              id='first-gender'
              name='gender'
              type='radio'
              value={0}
              onClick={(e) => {
                this.checkGender();
              }}
            />
            <label htmlFor='first-gender'> Madame</label>
          </div>
          <div className='snd-input--radio-bx'>
            <input
              id='second-gender'
              name='gender'
              type='radio'
              value={1}
              onClick={(e) => {
                this.checkGender();
              }}
            />
            <label htmlFor='second-gender'> Monsieur</label>
          </div>
          <div className='text--input-validation'>
            <img
              className='validation-icon'
              src={this.state.gender.isSuccess ? checked : error}
              alt='icon-verification'
            />
          </div>
        </div>
        <div className='form-control w-100 mobile'>
          <div className='fst-input--radio-bx mobile'>
            <input
              id='first-gender-mobile'
              name='gender'
              type='radio'
              value={0}
              onClick={(e) => {
                this.checkGender();
              }}
            />
            <label htmlFor='first-gender-mobile'> Madame</label>
          </div>
          <div className='snd-input--radio-bx mobile'>
            <input
              id='second-gender-mobile'
              name='gender'
              type='radio'
              value={1}
              onClick={(e) => {
                this.checkGender();
              }}
            />
            <label htmlFor='second-gender-mobile'> Monsieur</label>
          </div>
          <div className='text--input-validation'>
            <img
              className='validation-icon mobile'
              src={this.state.gender.isSuccess ? checked : error}
              alt='icon-verification'
            />
          </div>
        </div>
        <div className='form-control w-50'>
          <input
            id='lastname'
            className='text--input'
            name='name'
            type='text'
            placeholder='Nom'
            onChange={(e) => {
              this.checkName(e.target.value);
            }}
          />
          <div className='text--input-validation'>
            <img
              className='validation-icon'
              src={this.state.name.isSuccess ? checked : error}
              alt='icon-verification'
            />
          </div>
        </div>
        <div className='form-control w-50'>
          <input
            id='phone'
            className='text--input'
            name='phone'
            type='text'
            placeholder='Téléphone'
            onChange={(e) => {
              this.checkPhone(e.target.value);
            }}
          />
          <div className='text--input-validation'>
            <img
              className='validation-icon'
              src={this.state.phone.isSuccess ? checked : error}
              alt='icon-verification'
            />
          </div>
        </div>
        <div className='form-control w-50 desktop'>
          <input
            id='email'
            className='text--input'
            name='email'
            type='text'
            placeholder='Email'
            onChange={(e) => {
              this.checkEmail(e.target.value);
            }}
          />
          <div className='text--input-validation'>
            <img
              className='validation-icon'
              src={this.state.email.isSuccess ? checked : error}
              alt='icon-verification'
            />
          </div>
        </div>
        <div className='form-control w-100 mobile'>
          <input
            id='email'
            className='text--input mobile-input'
            name='email'
            type='text'
            placeholder='Email'
            onChange={(e) => {
              this.checkEmail(e.target.value);
            }}
          />
          <div className='text--input-validation mobile-input'>
            <img
              className='validation-icon'
              src={this.state.email.isSuccess ? checked : error}
              alt='icon-verification'
            />
          </div>
        </div>
        <div className='form-control w-100'>
          <div className='search-city--bx'>
            <DebounceInput
              minLength={2}
              debounceTimeout={300}
              id='city'
              className='text--input'
              name='city'
              type='text'
              placeholder='Ville'
              value={this.state.ville.value}
              autoComplete='off'
              onChange={(e) => {
                this.handleChange(e.target.value);
              }}
            />
            <ul
              id='villes'
              style={
                this.state.showList ? { display: 'block' } : { display: 'none' }
              }
            >
              {this.state.searchResultat.map((element, index) => (
                <li
                  key={index}
                  onClick={(e) => {
                    this.selectCity(element.name);
                  }}
                >
                  {element.name} ({element.zipcode})
                </li>
              ))}
            </ul>
          </div>
          <div className='text--input-validation'>
            <img
              className='validation-icon'
              src={this.state.ville.isSuccess ? checked : error}
              alt='icon-verification'
            />
            {this.state.loading ? (
              <CircularProgress
                color='inherit'
                size={20}
                className='progress--icon'
              />
            ) : (
              false
            )}
          </div>
        </div>
        <div className='form-control w-100 checkbox'>
          <div className='fst-checkbox--grp'>
            <div className='checkbox--bx'>
              <input
                id='newsletter'
                type='checkbox'
                className='checkbox--input'
                value={false}
                onClick={(e) => {
                  this.setState({
                    ...this.state,
                    newsletter: e.target.checked,
                  });
                }}
              />
              <label htmlFor='newsletter' className='label--checkbox'>
                J'accepte de recevoir la newsletter de korian par e-mail
              </label>
            </div>
            <div className='checkbox--bx'>
              <input
                id='offers'
                type='checkbox'
                className='checkbox--input'
                value={false}
                onClick={(e) => {
                  this.setState({
                    ...this.state,
                    commercial: e.target.checked,
                  });
                }}
              />
              <label htmlFor='offers' className='label--checkbox'>
                Je souhaite être informé des nouvelles offres de korian par
                e-mail
              </label>
            </div>
          </div>
          <div className='snd-checkbox--grp'>
            <div className='checkbox--bx'>
              <input
                id='urgent-checkbox'
                type='checkbox'
                className='checkbox--input urgent'
                value={false}
                onClick={(e) => {
                  this.setState({
                    ...this.state,
                    urgent: e.target.checked,
                  });
                }}
              />
              <label
                htmlFor='urgent-checkbox'
                className='label--checkbox urgent'
              >
                Demande urgente
                <span className='subtitle'>solution sous 48h</span>
              </label>
            </div>
          </div>
        </div>
        <div className='form-control w-100 flex-jst-center'>
          <Button
            variant='contained'
            className={'active'}
            // disabled={this.state.disabled}
            onClick={() => {
              this.onSubmit();
            }}
          >
            Envoyer votre demande
          </Button>
        </div>

        <div className='form-control w-100 text-center'>
          <p className='info'>
            Ces informations font l’objet d’un traitement dont la finalité est
            de vous aider à trouver un établissement Korian. Elles sont
            conservées pendant 2 ans. Conformément à la loi du 6 janvier 1978
            modifiée et au Règlement Général de Protection des Données, vous
            pouvez obtenir une copie de vos données, les rectifier ou les
            supprimer en nous écrivant au Service Clients en justifiant votre
            identité : Korian, Service Clients - 21-25 Rue
            <br />
            Balzac 75 008 Paris
            <br />
            <br />
            <a href='mailto:infoconseils@korian.fr'>infoconseils@korian.fr</a>.
            <br />
            Vous trouverez{' '}
            <a
              type='button'
              className={'btn btn-primary'}
              data-toggle='modal'
              data-target='#mention_legale'
            >
              ici
            </a>{' '}
            la notice d'information complète.
            <br />
            <br />
            *Du lundi au vendredi de 9h à 19h et le samedi de 9h à 13h
          </p>
        </div>
      </form>
    );
  }
}

export default ShortForm;
