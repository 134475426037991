import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import checked from './../assets/checked.png';
import error from './../assets/error-icon.svg';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { MdMyLocation, MdClose, MdLocationOn } from 'react-icons/md';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { DebounceInput } from 'react-debounce-input';
import CustomRequest from '../CustomRequest';

class LongForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      city: {
        value: '',
        isSuccess: false,
      },
      rayon: {
        value: '',
        isSuccess: false,
      },
      delai: {
        value: null,
        isSuccess: false,
      },
      typeAccueil: {
        value: null,
        isSuccess: false,
      },
      gender: {
        value: null,
        isSuccess: false,
      },
      name: {
        value: '',
        isSuccess: false,
      },
      firstname: {
        value: '',
        isSuccess: false,
      },
      phone: {
        value: '',
        isSuccess: false,
      },
      email: {
        value: '',
        isSuccess: false,
      },
      newsletter: false,
      commercial: false,
      urgent: false,
      step: 0,
      listHostTypes: [],
      listPeriods: [],
    };

    let customHeader = {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };
    let db_request = CustomRequest('host_types?type=geoloc&order[pos]=asc',
      customHeader
    );
    fetch(db_request).then((response) => {
      response
        .json()
        .then((data) => {
          let listData = data['hydra:member'];
          this.setState({
            ...this.state,
            listHostTypes: listData,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    });
    db_request = CustomRequest('periods?type=geoloc',
      customHeader
    );
    fetch(db_request).then((response) => {
      response
        .json()
        .then((data) => {
          let listData = data['hydra:member'];
          this.setState({
            ...this.state,
            listPeriods: listData,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }

  componentDidUpdate(prevProps, prevState) {}

  checkCityValue(value) {
    if (value.length > 3) {
      this.setState({
        ...this.state,
        city: { value: value, isSuccess: true },
      });
    } else {
      this.setState({
        ...this.state,
        city: { value: value, isSuccess: false },
      });
    }

    // application des classes css (success/error)
    setTimeout(() => {
      let city = document.querySelector('#city.text--input');
      if (this.state.city.isSuccess) {
        city.setAttribute('class', 'text--input success');
      } else {
        city.setAttribute('class', 'text--input error');
      }
    }, 100);
  }

  checkRayonValue(value) {
    if (value.length > 0) {
      this.setState({
        ...this.state,
        rayon: { value: value, isSuccess: true },
      });
    } else {
      this.setState({
        ...this.state,
        rayon: { value: value, isSuccess: false },
      });
    }

    // application des classes css (success/error)
    setTimeout(() => {
      let rayon = document.querySelector('#rayon.select--input');
      if (this.state.rayon.isSuccess) {
        rayon.setAttribute('class', 'select--input success');
      } else {
        rayon.setAttribute('class', 'select--input error');
      }
    }, 100);
  }

  setDelaiValue(value) {
    let firstChoice = document.querySelector('label[for="firstChoice"]');
    let secondChoice = document.querySelector('label[for="secondChoice"]');
    let fourthChoice = document.querySelector('label[for="fourthChoice"]');
    let fithChoice = document.querySelector('label[for="fithChoice"]');

    if (value !== null) {
      this.setState({
        ...this.state,
        delai: {
          value: value,
          isSuccess: true,
        },
      });
      // Mettre la couleur bleu par defaut
      firstChoice.setAttribute('style', 'color: black');
      secondChoice.setAttribute('style', 'color: black');
      fourthChoice.setAttribute('style', 'color: black');
      fithChoice.setAttribute('style', 'color: black');
    } else {
      // mettre les labels en rouge
      firstChoice.setAttribute('style', 'color: #ff0000');
      secondChoice.setAttribute('style', 'color: #ff0000');
      fourthChoice.setAttribute('style', 'color: #ff0000');
      fithChoice.setAttribute('style', 'color: #ff0000');
    }
  }

  CheckStep(step) {
    switch (step) {
      case 0:
        this.setState({
          ...this.state,
          step: step,
        });
        break;
      case 1:
        // Vérifions si tout les champs de la step 0 sont OK pour passer à la step 1
        if (
          this.state.city.isSuccess === true &&
          this.state.delai.isSuccess === true &&
          this.state.rayon.isSuccess === true
        ) {
          this.setState({
            ...this.state,
            step: step,
          });
        } else {
          this.checkCityValue(this.state.city.value);
          this.checkRayonValue(this.state.rayon.value);
          this.setDelaiValue(this.state.delai.value);
        }
        break;
      case 2:
        // Vérifions si tout les champs de la step 1 sont OK pour passer à la step 2
        if (this.state.typeAccueil.isSuccess === true) {
          this.setState({
            ...this.state,
            step: step,
          });
        } else {
          this.checkAccueilType();
        }
        break;
      case 3:
        // Vérifions si tout les champs de la step 2 sont OK pour passer à l'envoi du formulaire
        if (
          this.state.gender.isSuccess === true &&
          this.state.name.isSuccess === true &&
          this.state.firstname.isSuccess === true &&
          this.state.phone.isSuccess === true &&
          this.state.email.isSuccess === true
        ) {
          this.onSubmit();
        } else {
          this.checkGender();
          this.checkName(this.state.name.value);
          this.checkFirstname(this.state.firstname.value);
          this.checkPhone(this.state.phone.value);
          this.checkEmail(this.state.email.value);
        }
        break;
    }
  }

  onSubmit() {
    // Préparons les données du formulaire
    let dataToSend = {
      gender:
        this.state.gender.value === 1 || this.state.gender.value === '1'
          ? true
          : false,
      lastname: this.state.name.value,
      firstname: this.state.firstname.value,
      email: this.state.email.value,
      phone: this.state.phone.value,
      city: this.state.city.value,
      urgent: this.state.urgent,
      newsletter: this.state.newsletter,
      commercial: this.state.commercial,
      rayon: parseInt(this.state.rayon.value),
      delai: parseInt(this.state.delai.value),
      referer: window.location.search,
      type_accueil: parseInt(this.state.typeAccueil.value),
      hostType: this.state.listHostTypes.find((e) => {
        if (e.id === parseInt(this.state.typeAccueil.value)) {
          return e;
        }
      }),
    };
    this.props.handleSubmit(dataToSend);
  }

  checkAccueilType(e) {
    let value = e !== undefined ? e.target.value : null;
    let isChecked = e !== undefined ? e.target.checked : null;
    let listInput = document.querySelectorAll(
      '.lg-form .checkbox--bx .checkbox--input'
    );
    if (isChecked === true && this.state.typeAccueil.value !== value) {
      // traitement empechant la selection de plusieurs options
      for (let i = 0; i < listInput.length; i++) {
        if (listInput[i].checked === true && value !== listInput[i].value) {
          listInput[i].checked = false;
        }
      }
      this.setState({
        ...this.state,
        typeAccueil: {
          value: value,
          isSuccess: true,
        },
      });
    }
    if (isChecked === false && this.state.typeAccueil.value === value) {
      this.setState({
        ...this.state,
        typeAccueil: {
          value: null,
          isSuccess: false,
        },
      });
    }
    setTimeout(() => {
      let errorMsg = document.querySelector('p.error');
      let list = document.querySelectorAll(
        '.lg-form .checkbox--bx .label--checkbox'
      );
      if (!this.state.typeAccueil.isSuccess) {
        for (let e = 0; e < list.length; e++) {
          list[e].setAttribute('style', 'color: #ff0000');
          errorMsg.setAttribute('style', 'display: block; color:#ff0000;');
        }
      } else {
        for (let e = 0; e < list.length; e++) {
          list[e].setAttribute('style', 'color: #02405d');
          errorMsg.setAttribute('style', 'display: none');
        }
      }
    }, 50);
  }

  checkGender() {
    let element = document.querySelector('[name=gender]:checked');
    if (!this.state.gender.isSuccess && element !== null) {
      this.setState({
        ...this.state,
        gender: {
          value: element.value,
          isSuccess: true,
        },
      });
    }

    // vérifions si nous sommes en mobile ou desktop
    let mobileGender = document.querySelector('.form-control.w-100.mobile');
    if (mobileGender.offsetParent === null) {
      // Mode desktop
      // Modifier les classes pour afficher la bordure
      let fstDiv = document.querySelector('.fst-input--radio-bx');
      let sndDiv = document.querySelector('.snd-input--radio-bx');
      setTimeout(() => {
        if (this.state.gender.isSuccess) {
          fstDiv.setAttribute('class', 'fst-input--radio-bx success');
          sndDiv.setAttribute('class', 'snd-input--radio-bx success');
        } else {
          fstDiv.setAttribute('class', 'fst-input--radio-bx error');
          fstDiv.setAttribute('class', 'fst-input--radio-bx error');
          sndDiv.setAttribute('class', 'snd-input--radio-bx error');
        }
      }, 10);
    } else {
      // Mode mobile
      // Modifier les classes pour afficher la bordure
      let fstDiv = document.querySelector('.fst-input--radio-bx.mobile');
      let sndDiv = document.querySelector('.snd-input--radio-bx.mobile');
      setTimeout(() => {
        if (this.state.gender.isSuccess) {
          fstDiv.setAttribute('class', 'fst-input--radio-bx mobile success');
          sndDiv.setAttribute('class', 'snd-input--radio-bx mobile success');
        } else {
          fstDiv.setAttribute('class', 'fst-input--radio-bx mobile error');
          sndDiv.setAttribute('class', 'snd-input--radio-bx mobile error');
        }
      }, 10);
    }
  }

  checkName(value) {
    if (value && value.length > 2) {
      this.setState({
        ...this.state,
        name: {
          value: value,
          isSuccess: true,
        },
      });
      // Modifier la classes pour afficher la bordure success
      let name = document.querySelector('#lastname.text--input');
      name.setAttribute('class', 'text--input success');
    } else {
      this.setState({
        ...this.state,
        name: {
          value: value,
          isSuccess: false,
        },
      });
      // Modifier la classes pour afficher la bordure erreur
      let name = document.querySelector('#lastname.text--input');
      name.setAttribute('class', 'text--input error');
    }
  }

  checkFirstname(value) {
    if (value && value.length > 2) {
      this.setState({
        ...this.state,
        firstname: {
          value: value,
          isSuccess: true,
        },
      });
      // Modifier la classes pour afficher la bordure success
      let firstname = document.querySelector('#firstname.text--input');
      firstname.setAttribute('class', 'text--input success');
    } else {
      this.setState({
        ...this.state,
        firstname: {
          value: value,
          isSuccess: false,
        },
      });
      // Modifier la classes pour afficher la bordure erreur
      let firstname = document.querySelector('#firstname.text--input');
      firstname.setAttribute('class', 'text--input error');
    }
  }

  checkPhone(value) {
    if (
      /[0-9]{10}/.test(value) &&
      value.length === 10 &&
      (value[0] === '0' || value[0] === 0)
    ) {
      this.setState({
        ...this.state,
        phone: {
          value: value,
          isSuccess: true,
        },
      });
      // vérifions si nous sommes en mobile ou desktop
      let phoneEmail = document.querySelector('.form-control.w-100.mobile');
      if (phoneEmail.offsetParent === null) {
        // Modifier la classes pour afficher la bordure success
        let phone = document.querySelector('#phone.text--input');
        phone.setAttribute('class', 'text--input success');
      } else {
        // Modifier la classes pour afficher la bordure success
        let phone = document.querySelector('#phone.text--input.mobile-input');
        phone.setAttribute('class', 'text--input mobile-input success');
      }
    } else {
      this.setState({
        ...this.state,
        phone: {
          value: value,
          isSuccess: false,
        },
      });
      let phoneEmail = document.querySelector('.form-control.w-100.mobile');
      if (phoneEmail.offsetParent === null) {
        // Modifier la classes pour afficher la bordure erreur
        let phone = document.querySelector('#phone.text--input');
        phone.setAttribute('class', 'text--input error');
      } else {
        // Modifier la classes pour afficher la bordure erreur
        let phone = document.querySelector('#phone.text--input.mobile-input');
        phone.setAttribute('class', 'text--input mobile-input error');
      }
    }
  }

  checkEmail(value) {
    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (value && regex.test(value.toLowerCase())) {
      this.setState({
        ...this.state,
        email: {
          value: value,
          isSuccess: true,
        },
      });
      // vérifions si nous sommes en mobile ou desktop
      let mobilEmail = document.querySelector('.form-control.w-100.mobile');
      if (mobilEmail.offsetParent === null) {
        // Modifier la classes pour afficher la bordure success
        let email = document.querySelector('#email.text--input');
        email.setAttribute('class', 'text--input success');
      } else {
        // Modifier la classes pour afficher la bordure success
        let email = document.querySelector('#email.text--input.mobile-input');
        email.setAttribute('class', 'text--input mobile-input success');
      }
    } else {
      this.setState({
        ...this.state,
        email: {
          value: value,
          isSuccess: false,
        },
      }); // vérifions si nous sommes en mobile ou desktop
      let mobilEmail = document.querySelector('.form-control.w-100.mobile');
      if (mobilEmail.offsetParent === null) {
        // Modifier la classes pour afficher la bordure erreur
        let email = document.querySelector('#email.text--input');
        email.setAttribute('class', 'text--input error');
      } else {
        // Modifier la classes pour afficher la bordure erreur
        let email = document.querySelector('#email.text--input.mobile-input');
        email.setAttribute('class', 'text--input mobile-input error');
      }
    }
  }

  showForm() {
    const { listHostTypes, listPeriods } = this.state;
    switch (this.state.step) {
      case 0:
        return (
          <React.Fragment>
            <div className='form-control w-100'>
              <h4 className='header-subtitle'>
                {' '}
                Dans quelle ville recherchez-vous ?
              </h4>
            </div>

            <div className='form-control w-50 '>
              <input
                id='city'
                className='text--input'
                name='name'
                type='text'
                placeholder='Exemple: 75000 ou Paris'
                value={this.state.city.value}
                onChange={(e) => {
                  this.checkCityValue(e.target.value);
                }}
              />
            </div>
            <div className='form-control w-50'>
              <select
                name='rayon'
                id='rayon'
                className='select--input'
                onChange={(e) => {
                  this.checkRayonValue(e.target.value);
                }}
                value={this.state.rayon.value}
              >
                <option value=''>-- Dans un rayon de --</option>
                <option value='5'>5 km</option>
                <option value='10'>10 km</option>
                <option value='20'>20 km</option>
                <option value='30'>30 km</option>
              </select>
            </div>
            <div className='form-control w-100'>
              <h4 className='header-subtitle'>
                {' '}
                Quel délai d'entrée souhaitez-vous ?
              </h4>
            </div>
            <div className='form-control w-100 radios'>
              {listPeriods.map((period, i) => {
                switch (i) {
                  case 0:
                    return (
                      <div className={'first w-50'} key={i}>
                        <input
                          id={'firstChoice'}
                          className='text--input'
                          name='delai'
                          type='radio'
                          value={period.id}
                          defaultChecked={
                            parseInt(this.state.delai.value) === period.id
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            this.setDelaiValue(e.target.value);
                          }}
                        />
                        <label htmlFor={'firstChoice'}> {period.name}</label>
                      </div>
                    );
                  case 1:
                    return (
                      <React.Fragment key={i}>
                        <div className={'second w-50'}>
                          <input
                            id={'secondChoice'}
                            className='text--input'
                            name='delai'
                            type='radio'
                            value={period.id}
                            defaultChecked={
                              parseInt(this.state.delai.value) === period.id
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              this.setDelaiValue(e.target.value);
                            }}
                          />
                          <label htmlFor={'secondChoice'}>{period.name}</label>
                        </div>
                        <hr className='third w-100' />
                      </React.Fragment>
                    );
                  case 2:
                    return (
                      <div className={'fourth w-50'} key={i}>
                        <input
                          id={'fourthChoice'}
                          className='text--input'
                          name='delai'
                          type='radio'
                          value={period.id}
                          defaultChecked={
                            parseInt(this.state.delai.value) === period.id
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            this.setDelaiValue(e.target.value);
                          }}
                        />
                        <label htmlFor={'fourthChoice'}> {period.name}</label>
                      </div>
                    );
                  case 3:
                    return (
                      <div className={'fith w-50'} key={i}>
                        <input
                          id={'fithChoice'}
                          className='text--input'
                          name='delai'
                          type='radio'
                          value={period.id}
                          defaultChecked={
                            parseInt(this.state.delai.value) === period.id
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            this.setDelaiValue(e.target.value);
                          }}
                        />
                        <label htmlFor={'fithChoice'}> {period.name}</label>
                      </div>
                    );
                  default:
                    break;
                }
              })}
            </div>
            <div className='form-control w-100 flex-jst-center'>
              <Button
                variant='contained'
                className={'active'}
                onClick={() => {
                  this.CheckStep(1);
                }}
              >
                Valider
              </Button>
            </div>
          </React.Fragment>
        );

      case 1:
        return (
          <React.Fragment>
            <div className='form-control w-100'>
              <h4 className='header-subtitle'>
                {' '}
                Quel type d'accueil souhaitez-vous ?
              </h4>
            </div>
            <div className='form-control w-100 checkbox'>
              <div className='fst-checkbox--grp lg-form'>
                {listHostTypes.map((hostType, i) => {
                  return (
                    <div className='checkbox--bx' key={i}>
                      <input
                        id={i + 'checkbox'}
                        type='checkbox'
                        className='checkbox--input'
                        value={hostType.id}
                        defaultChecked={
                          parseInt(this.state.typeAccueil.value) === hostType.id
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          this.checkAccueilType(e);
                        }}
                      />
                      <label
                        htmlFor={i + 'checkbox'}
                        className='label--checkbox'
                      >
                        {hostType.name}
                      </label>
                    </div>
                  );
                })}
                <p className='error' style={{ display: 'none' }}>
                  Vous devez sélectionner une option
                </p>
              </div>
            </div>
            <div className='form-control w-100 flex-jst-center'>
              <Button
                variant='contained'
                className={'active'}
                onClick={() => {
                  this.CheckStep(2);
                }}
              >
                Valider
              </Button>
              <br />
              <a
                onClick={() => {
                  this.CheckStep(0);
                }}
                className='back-step'
              >
                Retour
              </a>
            </div>
          </React.Fragment>
        );

      case 2:
        return (
          <React.Fragment>
            {/* <div className='form-control w-100 desktop'>
              <h4 className='header-subtitle'> Vos coordonnées </h4>
            </div> */}
            <div className='form-control w-100-lg desktop'>
              <div className='fst-input--radio-bx'>
                <input
                  id='first-gender'
                  name='gender'
                  type='radio'
                  value={0}
                  defaultChecked={
                    this.state.gender.value === '0' ? true : false
                  }
                  onChange={(e) => {
                    this.checkGender();
                  }}
                />
                <label htmlFor='first-gender'> Madame</label>
              </div>
              <div className='snd-input--radio-bx'>
                <input
                  id='second-gender'
                  name='gender'
                  type='radio'
                  value={1}
                  defaultChecked={
                    this.state.gender.value === '1' ? true : false
                  }
                  onChange={(e) => {
                    this.checkGender();
                  }}
                />
                <label htmlFor='second-gender'> Monsieur</label>
              </div>
            </div>
            <div className='form-control w-100-lg mobile'>
              <div className='fst-input--radio-bx mobile'>
                <input
                  id='first-gender-mobile'
                  name='gender'
                  type='radio'
                  value={0}
                  defaultChecked={
                    this.state.gender.value === '0' ? true : false
                  }
                  onChange={(e) => {
                    this.checkGender();
                  }}
                />
                <label htmlFor='first-gender-mobile'> Madame</label>
              </div>
              <div className='snd-input--radio-bx mobile'>
                <input
                  id='second-gender-mobile'
                  name='gender'
                  type='radio'
                  value={1}
                  defaultChecked={
                    this.state.gender.value === '1' ? true : false
                  }
                  onChange={(e) => {
                    this.checkGender();
                  }}
                />
                <label htmlFor='second-gender-mobile'> Monsieur</label>
              </div>
            </div>
            <div className='form-control w-50'>
              <input
                id='lastname'
                className='text--input'
                name='lastname'
                type='text'
                placeholder='Nom'
                value={this.state.name.value}
                onChange={(e) => {
                  this.checkName(e.target.value);
                }}
              />
            </div>

            <div className='form-control w-50'>
              <input
                id='firstname'
                className='text--input'
                name='firstname'
                type='text'
                placeholder='Prenom(s)'
                value={this.state.firstname.value}
                onChange={(e) => {
                  this.checkFirstname(e.target.value);
                }}
              />
            </div>
            <div className='form-control w-50 desktop'>
              <input
                id='phone'
                className='text--input'
                name='phone'
                type='text'
                placeholder='Téléphone'
                value={this.state.phone.value}
                onChange={(e) => {
                  this.checkPhone(e.target.value);
                }}
              />
            </div>
            <div className='form-control w-100 mobile'>
              <input
                id='phone'
                className='text--input mobile-input'
                name='phone'
                type='text'
                placeholder='Téléphone'
                value={this.state.phone.value}
                onChange={(e) => {
                  this.checkPhone(e.target.value);
                }}
              />
            </div>
            <div className='form-control w-50 desktop'>
              <input
                id='email'
                className='text--input'
                name='email'
                type='text'
                placeholder='Email'
                value={this.state.email.value}
                onChange={(e) => {
                  this.checkEmail(e.target.value);
                }}
              />
            </div>
            <div className='form-control w-100 mobile'>
              <input
                id='email'
                className='text--input mobile-input'
                name='email'
                type='text'
                placeholder='Email'
                value={this.state.email.value}
                onChange={(e) => {
                  this.checkEmail(e.target.value);
                }}
              />
            </div>

            <div className='form-control w-100 checkbox'>
              <div className='fst-checkbox--grp'>
                <div className='checkbox--bx'>
                  <input
                    id='newsletter'
                    type='checkbox'
                    className='checkbox--input'
                    value={false}
                    onClick={(e) => {
                      this.setState({
                        ...this.state,
                        newsletter: e.target.checked,
                      });
                    }}
                  />
                  <label htmlFor='newsletter' className='label--checkbox'>
                    J'accepte de recevoir la newsletter de korian par e-mail
                  </label>
                </div>
                <div className='checkbox--bx'>
                  <input
                    id='offers'
                    type='checkbox'
                    className='checkbox--input'
                    value={false}
                    onClick={(e) => {
                      this.setState({
                        ...this.state,
                        commercial: e.target.checked,
                      });
                    }}
                  />
                  <label htmlFor='offers' className='label--checkbox'>
                    Je souhaite être informé des nouvelles offres de korian par
                    e-mail
                  </label>
                </div>
              </div>
              <div className='snd-checkbox--grp'>
                <div className='checkbox--bx'>
                  <input
                    id='urgent-checkbox'
                    type='checkbox'
                    className='checkbox--input urgent'
                    value={false}
                    onClick={(e) => {
                      this.setState({
                        ...this.state,
                        urgent: e.target.checked,
                      });
                    }}
                  />
                  <label
                    htmlFor='urgent-checkbox'
                    className='label--checkbox urgent'
                  >
                    Demande urgente
                    <span className='subtitle'>solution sous 48h</span>
                  </label>
                </div>
              </div>
            </div>
            <div className='form-control w-100 flex-jst-center'>
              <Button
                variant='contained'
                className={'active'}
                onClick={() => {
                  this.CheckStep(3);
                }}
              >
                Envoyer votre demande
              </Button>
              <br />
              <a
                onClick={() => {
                  this.CheckStep(1);
                }}
                className='back-step'
              >
                Retour
              </a>
            </div>
            <div className='form-control w-100 text-center'>
              <p className='info'>
                Ces informations font l’objet d’un traitement dont la finalité
                est de vous aider à trouver un établissement Korian. Elles sont
                conservées pendant 2 ans. Conformément à la loi du 6 janvier
                1978 modifiée et au Règlement Général de Protection des Données,
                vous pouvez obtenir une copie de vos données, les rectifier ou
                les supprimer en nous écrivant au Service Clients en justifiant
                votre identité : Korian, Service Clients - 21-25 Rue
                <br />
                Balzac 75 008 Paris
                <a href='mailto:infoconseils@korian.fr'>
                  infoconseils@korian.fr
                </a>
                .
                <br />
                Vous trouverez{' '}
                <a
                  type='button'
                  className={'btn btn-primary'}
                  data-toggle='modal'
                  data-target='#mention_legale'
                >
                  ici
                </a>{' '}
                la notice d'information complète.
                <br />
                <br />
                *Du lundi au vendredi de 9h à 19h et le samedi de 9h à 13h
              </p>
            </div>
          </React.Fragment>
        );

      default:
        break;
    }
  }

  render() {
    return (
      <form id='longForm' className='form--content'>
        {this.showForm()}
      </form>
    );
  }
}

export default LongForm;
