import React, { Component } from 'react';
// ASSETS
import icon from '../assets/icon/icon-s2.svg';
import icon_s1 from './../assets/icon/icon-bilan.svg';
import icon_s2 from './../assets/icon/icon-therapie.svg';
import icon_s3 from './../assets/icon/icon-accompagnement.svg';
import icon_s4 from './../assets/icon/icon-visite.svg';
import icon_s5 from './../assets/icon/icon-equipe.svg';
import icon_s6 from './../assets/icon/icon-solution.svg';

class ServicesTwo extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    return (
      <div className='services-two-infos--bx'>
        <div className='header-service--bx'>
          <img
            src={icon}
            alt='bien-chez-vous-tout-simplement'
            className='icon--services'
          />
          <h2 className='title--services'>
            POURQUOI CHOISIR LES MAISONS DE RETRAITE KORIAN
          </h2>
        </div>
        <p className='txt--services'>
          <b>Toujours à l’écoute des vos besoins,</b> nous vous accompagnons
          pour trouver la maison de retraite qui vous correspond. Notre{' '}
          <b>personnel médical régulièrement formé</b> s’engage pour améliorer
          votre confort de vie et vous fournir les meilleurs soins. Dans une{' '}
          <b>atmosphère chaleureuse et attentive</b>, nous facilitons votre
          entrée et votre séjour.
        </p>
        <div className='details-services--bx'>
          <div className='service--bx'>
            <div className='service--icon'>
              <img src={icon_s1} alt='bilan-gériatrique' />
            </div>
            <div className='service--txt'>
              <span>
                <b>Bilan gériatrique</b>
              </span>
              <span>dès l'entrée</span>
            </div>
          </div>

          <div className='service--bx'>
            <div className='service--icon'>
              <img src={icon_s2} alt='thérapies-non-médicamenteuse' />
            </div>
            <div className='service--txt'>
              <span>Thérapies</span>
              <span>
                <b>non médicamenteuse</b>
              </span>
            </div>
          </div>

          <div className='service--bx'>
            <div className='service--icon'>
              <img src={icon_s3} alt='accompagnement-spécifique' />
            </div>
            <div className='service--txt'>
              <span>
                <b>
                  Accompagnement <br />
                  spécifique
                </b>
              </span>
              <span>Alzheimer, Parkinson...</span>
            </div>
          </div>

          <div className='service--bx'>
            <div className='service--icon'>
              <img src={icon_s4} alt='visite-à-domicile' />
            </div>
            <div className='service--txt'>
              <span>
                Visite <b>à domicile</b> possible
              </span>
              <span>dès votre entrée</span>
            </div>
          </div>

          <div className='service--bx'>
            <div className='service--icon'>
              <img src={icon_s5} alt='équipe-soignante' />
            </div>
            <div className='service--txt'>
              <span>
                <b>Équipe soignante </b>
              </span>
              <span>24h/24</span>
            </div>
          </div>

          <div className='service--bx'>
            <div className='service--icon'>
              <img src={icon_s6} alt='solution-rapide' />
            </div>
            <div className='service--txt'>
              <span>
                <b>Solution</b>
              </span>
              <span>en moins de 48h</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServicesTwo;
