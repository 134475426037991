import React, { Component } from 'react';
// ASSETS
import icon from '../assets/icon/icon-s1.svg';
import { Button } from '@material-ui/core';

import bg from './../assets/bg/bg--services.png';
import bg_webp from './../assets/bg/bg--services.png.webp';

import bg2 from './../assets/bg/bg--services-mobile.png';
import bg2_webp from './../assets/bg/bg--services-mobile.png.webp';
class Services extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  jumpTo() {
    let element = document.querySelector('#header');
    let parent = window;
    parent.scrollTo({
      top: element.offsetTop,
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  render() {
    return (
      <div className='services-infos--bx'>
        <div className='header-service--bx'>
          <img
            src={icon}
            alt='bien-chez-vous-tout-simplement'
            className='icon--services'
          />
          <h2 className='title--services'>BIEN CHEZ VOUS TOUT SIMPLEMENT</h2>
        </div>
        <p className='txt--services'>
          Préserver votre bien-être en{' '}
          <b>
            respectant vos choix et vos envies est notre engagement fondamental.
          </b>{' '}
          Prendre soin de soi, manger ce que l’on aime, se faire plaisir… ces
          instants privilégiés sont <b>essentiels à votre bien-être.</b> C’est
          pourquoi nous vous proposons{' '}
          <b>une gamme de services personnalisés.</b>
        </p>
        <div className='details-services--bx'>
          <div className='image-services'>
            {window.screen.width > 1024 ? (
              <picture style={{ height: '400px' }}>
                <source srcSet={bg_webp} type='image/webp' />
                <source srcSet={bg_webp} type='image/jpeg' />
                <img srcSet={bg_webp} src={bg} alt='img-bg--services' />
              </picture>
            ) : (
              <picture style={{ height: 'calc(100% - 14px)' }}>
                <source srcSet={bg2_webp} type='image/webp' />
                <source srcSet={bg2_webp} type='image/jpeg' />
                <img
                  srcSet={bg2_webp}
                  src={bg2}
                  alt='img-bg--services'
                  style={{ width: '100%' }}
                />
              </picture>
            )}

            {/* <img srcSet={bg2_webp} src={bg2} alt='img-bg--services' /> */}
            <div className='bx-footer--color flex'>
              <span className='fst-footer--color'></span>
              <span className='snd-footer--color'></span>
            </div>
          </div>
          <div className='services--list'>
            <ul>
              <li>
                <span>
                  <b>Chambre personnalisable</b> pour se sentir comme chez soi
                </span>
              </li>
              <li>
                <span>
                  <b>Activités en fonction des envies et capacités de chacun</b>
                </span>
              </li>
              <li>
                <span>
                  <b>
                    Une liberté d'aller et de venir dans un cadre sécurisé et de
                    recevoir,
                  </b>
                  <br />
                  sortir ou partir en vacances avec ses proches
                </span>
              </li>
              <li>
                <span>
                  <b>Une cuisine saine et savoureuse, au choix</b>
                  (plat alternatif, petit dejeuner
                  <br /> en chambre ou au salon, choix de sa place à table...)
                </span>
              </li>
              <li>
                <span>
                  <b>Des sorties extérieures régulières </b> pour garder ses
                  habitudes de vie
                </span>
              </li>
              <li>
                <span>
                  <b>Programme </b> de soin <b>sur-mesure</b>
                </span>
              </li>
            </ul>

            <Button
              variant='contained'
              className='btn--info'
              onClick={() => {
                this.jumpTo();
              }}
            >
              EN SAVOIR PLUS
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
