import React, { Component } from 'react';
// ASSETS
import { Button } from '@material-ui/core';
import icon from '../assets/icon/icon-s3.svg';
import icon_s1 from './../assets/icon/icon-cs.svg';
import icon_s2 from './../assets/icon/icon-ls.svg';
import icon_s3 from './../assets/icon/icon-accueil.svg';
import Carousel from 'nuka-carousel';

class TypeSejours extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  jumpTo() {
    let element = document.querySelector('#header');
    let parent = window;
    parent.scrollTo({
      top: element.offsetTop,
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }

  render() {
    return (
      <div className='sejours-infos--bx'>
        <div className='header-sejour--bx'>
          <img
            src={icon}
            alt='bien-chez-vous-tout-simplement'
            className='icon--sejours'
          />
          <h2 className='title--sejours'>CHOISISSEZ VOTRE DURÉE DE SÉJOUR</h2>
        </div>
        <p className='txt--sejours'>
          Comme chaque besoin est spécifique, nous vous proposons{' '}
          <b>3 solutions de séjours en maison de retraite</b> pour répondre au
          mieux à vos attentes :
        </p>

        <div className='details-sejours--bx'>
          <div className='sejour--bx'>
            <div className='sejour--content'>
              <div className='sejour--icon'>
                <img src={icon_s1} alt='court-séjour' />
                <span className='title--sejour p-t-15'>
                  <b>Court séjour</b>
                </span>
              </div>
              <div className='sejour--txt'>
                <p>
                  une solution temporaire, de deux jours à plusieurs mois, pour
                  pallier les imprévus (absence d’un aidant, sortie
                  d’hospitalisation, rééducation…)
                </p>
              </div>
            </div>
          </div>

          <div className='right--divider'>
            <span className='fst--divider'></span>
            <span className='snd--divider'></span>
          </div>

          <div className='sejour--bx'>
            <div className='sejour--content'>
              <div className='sejour--icon'>
                <img src={icon_s2} className='po-r-t-10' alt='long-séjour' />
                <span className='title--sejour p-t-15'>
                  <b>Long séjour</b>
                </span>
              </div>
              <div className='sejour--txt'>
                <p>
                  une solution permanente, pour pouvoir vivre en sécurité et
                  recevoir des soins adaptés à votre situation.
                </p>
              </div>
            </div>
          </div>

          <div className='right--divider'>
            <span className='fst--divider'></span>
            <span className='snd--divider'></span>
          </div>

          <div className='sejour--bx'>
            <div className='sejour--content'>
              <div className='sejour--icon'>
                <img
                  src={icon_s3}
                  className='po-r-t-25'
                  alt='accueil-de-jour'
                />
                <span className='title--sejour'>
                  <b>Accueil de jour</b>
                </span>
              </div>
              <div className='sejour--txt'>
                <p>
                  une solution d’accueil souple et modulable, en journée ou en
                  demi-journée, une ou plusieurs fois par semaine.
                </p>
              </div>
            </div>
          </div>
          <Button
            variant='contained'
            className='btn--info'
            onClick={() => {
              this.jumpTo();
            }}
          >
            EN SAVOIR PLUS
          </Button>
        </div>

        <div className='details-sejours--mobile-bx'>
          <Carousel
            autoplay={true}
            disableEdgeSwiping={true}
            autoplayReverse={false}
            renderCenterLeftControls={({ previousSlide }) => null}
            renderCenterRightControls={({ nextSlide }) => null}
            defaultControlsConfig={{
              pagingDotsStyle: {
                fill: 'transparent',
              },
            }}
          >
            <div className='sejour--bx'>
              <div className='sejour--content'>
                <div className='sejour--icon'>
                  <img src={icon_s1} alt='bilan-gériatrique' />
                  <span className='title--sejour p-t-15'>
                    <b>Court séjour</b>
                  </span>
                </div>
                <div className='sejour--txt'>
                  <p>
                    une solution temporaire, de deux jours à plusieurs mois,
                    pour pallier les imprévus (absence d’un aidant, sortie
                    d’hospitalisation, rééducation…)
                  </p>
                </div>
              </div>
            </div>

            <div className='sejour--bx'>
              <div className='sejour--content'>
                <div className='sejour--icon'>
                  <img
                    src={icon_s2}
                    className='po-r-t-10'
                    alt='bilan-gériatrique'
                  />
                  <span className='title--sejour p-t-40'>
                    <b>Long séjour</b>
                  </span>
                </div>
                <div className='sejour--txt'>
                  <p>
                    une solution permanente, pour pouvoir vivre en sécurité et
                    recevoir des soins adaptés à votre situation.
                  </p>
                </div>
              </div>
            </div>

            <div className='sejour--bx'>
              <div className='sejour--content'>
                <div className='sejour--icon'>
                  <img
                    src={icon_s3}
                    className='po-r-t-25'
                    alt='bilan-gériatrique'
                  />
                  <span className='title--sejour'>
                    <b>Accueil de jour</b>
                  </span>
                </div>
                <div className='sejour--txt'>
                  <p>
                    une solution d’accueil souple et modulable, en journée ou en
                    demi-journée, une ou plusieurs fois par semaine.
                  </p>
                </div>
              </div>
            </div>
          </Carousel>
          <Button
            variant='contained'
            className='btn--info'
            onClick={() => {
              this.jumpTo();
            }}
          >
            EN SAVOIR PLUS
          </Button>
        </div>
      
        <div className="mention-covid">
            <div className="covid-content">
              <span>
                *Les protocoles peuvent évoluer en fonction de la situation épidémique.
                Plus d’info sur <a
                  href="https://www.korian.fr/les-actualites/foire-aux-questions-nos-mesures-face-au-coronavirus-covid19"
                  target="_blank">
                  https://www.korian.fr/les-actualites/foire-aux-questions-nos-mesures-face-au-coronavirus-covid19
                </a>
					    </span>
            </div>
        </div>
      </div>
    );
  }
}

export default TypeSejours;
