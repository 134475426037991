import React, { Component } from 'react';
// COMPONENTS
import Carousel from 'nuka-carousel';

class ThreeBlocksInfo extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className='infos--bx'>
          <div className='fst-info--bx'>
            <div className='info-text'>
              <span>23 000 familles</span>
              <span>nous font confiance</span>
              <span>chaque année</span>
            </div>
          </div>
          <div className='snd-info--bx'>
            <div className='info-text'>
              <span>94 %</span>
              <span>de familles satisfaites</span>
              <small>Source enquête de satisfaction 2018</small>
            </div>
          </div>
          <div className='thd-info--bx'>
            <div className='info-text'>
              <span>Notre équipe vous</span>
              <span>
                <strong>rencontre</strong> à domicile
              </span>
              <span>avant votre entrée</span>
            </div>
          </div>
        </div>

        <div className='infos--mobile-bx'>
          <Carousel
            autoplay={true}
            disableEdgeSwiping={true}
            autoplayReverse={false}
            renderCenterLeftControls={({ previousSlide }) => null}
            renderCenterRightControls={({ nextSlide }) => null}
            defaultControlsConfig={{
              pagingDotsStyle: {
                fill: 'transparent'
              }
            }}
          >
            <div className='fst-info--bx'>
              <div className='info-text'>
                <span>23 000 familles</span>
                <span>nous font confiance</span>
                <span>chaque année</span>
              </div>
            </div>
            <div className='snd-info--bx'>
              <div className='info-text'>
                <span>94 %</span>
                <span>de familles satisfaites</span>
                <small>Source enquête de satisfaction 2018</small>
              </div>
            </div>
            <div className='thd-info--bx'>
              <div className='info-text'>
                <span>Notre équipe vous</span>
                <span>
                  <strong>rencontre</strong> à domicile
                </span>
                <span>avant votre entrée</span>
              </div>
            </div>
          </Carousel>
        </div>
      </React.Fragment>
    );
  }
}

export default ThreeBlocksInfo;
