import React, { Component } from 'react';
import './assets/css/App.scss';

// COMPONENTS
import LpLongForm from './LpLongForm';
import LpShortForm from './LpShortForm';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

class App extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      datalayer: null,
    };
  }

  componentWillMount() {
    var result;
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
      result = w[l];
    })(window, document, 'script', 'dataLayer', 'GTM-PPR3QQ');
    this.setState({
      ...this.state,
      dataLayer: result,
    });
  }

  gtmFunction(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path='/formulaire-long-v2'>
            <LpLongForm dataLayer={this.state.dataLayer} />
          </Route>
          <Route path='/'>
            <LpShortForm dataLayer={this.state.dataLayer} />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
