import React, { Component } from 'react';
import Logo from './assets/logo-white.png';
import Logo2 from './assets/Logo_ESCDA_2024.png';
import './assets/css/App.scss';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

// COMPONENTS
import MainHeader from './header/MainHeader';
import MapBlock from './mapBlock/MapBlock';
import ThreeBlocksInfo from './others/ThreeBlocksInfo';
import Services from './others/Services';
import Testimonials from './others/Testimonials';
import ServicesTwo from './others/ServicesTwo';
import Button from '@material-ui/core/Button';
import TypeSejours from './others/TypeSejours';
import Footer from './others/footer';
import { Helmet } from 'react-helmet';

// FOR ENV VARIABLES
const dotenv = require('dotenv');
dotenv.config();

class App extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.centerRef = React.createRef();
    this.state = {
      open: false,
      searchPlace: null,
    };
  }

  setSearchPlace(newPlace) {
    this.setState({
      ...this.state,
      searchPlace: newPlace,
    });
  }

  render() {
    const { searchPlace } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet='utf-8' />
          <title>
            {searchPlace !== null
              ? 'Choisir sa maison de retraite Korian - ' + searchPlace
              : 'Choisir sa maison de retraite Korian'}
          </title>
          <meta name='description' content='Landing page géolocalisée' />
        </Helmet>
        <AppBar position='fixed'>
          <div className='inner'>
            <Toolbar>
              <a href="https://www.korian.fr/" target="_blank">
                <img className='whiteLogo' src={Logo} alt='korian-logo-white' />
              </a>
            </Toolbar>
            <div className='spacer'></div>
            <div className='btn--service'>
              <span className='slogan'>Au service du Bien-vieillir</span>
              <Button
                className='btn--call'
                onClick={() => {
                  window.location.href = 'tel:0185657626';
                }}
              >
                01 85 65 76 26
                <small className='subtitle'>PRIX D’UN APPEL LOCAL*</small>
              </Button>
              <div className='logo-escda-mobile'>
                <img src={Logo2} alt='korian-logo-escda' />
              </div>
            </div>
            <div className='logo-escda'>
              <img src={Logo2} alt='korian-logo-escda' />
            </div>
          </div>
        </AppBar>
        <div className='main' id='header'>
          {/* MAIN HEADER */}
          <MainHeader
            searchPlace={this.state.searchPlace}
            formType={'short'}
            dataLayer={this.props.dataLayer}
          />

          {/* MAP BLOCK */}
          <MapBlock
            searchPlace={this.state.searchPlace}
            setPlace={(newValue) => {
              this.setSearchPlace(newValue);
            }}
            formType={'short'}
          />

          {/* THREE BLOCKS INFO */}
          <ThreeBlocksInfo />

          {/* BIEN CHEZ VOUS TOUT SIMPLEMENT */}
          <Services />

          {/* TESTIMONIALS */}
          <Testimonials />

          {/* POURQUOI CHOISIR LES MAISONS DE RETRAITE KORIAN */}
          <ServicesTwo />

          {/* CHOISISSEZ VOTRE DURÉE DE SÉJOUR */}
          <TypeSejours />

          {/* FOOTER */}
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default App;
