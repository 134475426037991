import React, { Component } from 'react';
// ASSETS
import bg from './../assets/bg/bg--testimonials.png';
import bg_webp from './../assets/bg/bg--testimonials.png.webp';
import Carousel from 'nuka-carousel';
import { GoQuote } from 'react-icons/go';

class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    return (
      <div className='testimonials-infos--bx'>
        <div className='testimonials--list'>
          <GoQuote className='testimonial--quote' />
          <Carousel
            autoplay={true}
            disableEdgeSwiping={true}
            autoplayReverse={false}
            renderCenterLeftControls={({ previousSlide }) => null}
            renderCenterRightControls={({ nextSlide }) => null}
            defaultControlsConfig={{
              pagingDotsStyle: {
                fill: 'transparent',
              },
            }}
          >
            <div className='testimonial'>
              <div className='testimonial-content'>
                <p>
                  <em>
                    Je constate une dynamique exceptionnelle dans cette maison.
                    Le personnel est à l'écoute, toujours bienveillant et
                    disponible, mettant tout en œuvre pour assurer le confort du
                    résident. La présence permanente du personnel médical et de
                    "l'accueil" tout la journée y compris les week-ends et les
                    jours fériés est très rassurante pour les résidents et les
                    familles.
                  </em>
                </p>
                <span>Anne P, Famille,</span>
                <span>Korian Le Gentilé</span>
              </div>
            </div>
            <div className='testimonial'>
              <div className='testimonial-content'>
                <p>
                  <em>
                    Accueillis dans une situation d'urgence la directrice et ses
                    équipes ont su prendre en compte nos difficultés. Ils ont
                    pris soin de notre mère avec bienveillance et
                    professionnalisme dans un contexte extrêmement compliqué
                    pour nous. MERCI ENCORE DE TOUT NOTRE COEUR. Nous vous
                    sommes extrêmement reconnaissants et recommandons vivement
                    cet établissement.
                  </em>
                </p>
                <span>Myriam V, Famille,</span>
                <span>Korian Reine Mathilde</span>
              </div>
            </div>
            <div className='testimonial'>
              <div className='testimonial-content'>
                <p>
                  <em>
                    Maman vient de me dire à brûle-pourpoint « Je suis vraiment
                    bien ici ». Et j’ai réalisé qu’on oubliait de vous dire «
                    Merci », tellement nos parents se sont glissés avec douceur
                    dans l’environnement calme et attentionné auquel toute
                    l’équipe contribue, chaque jour, sans relâche, avec tact et
                    discrétion… Merci !
                  </em>
                </p>
                <span>Pierre-Alain M, Famille,</span>
                <span>Korian Monceau</span>
              </div>
            </div>
            <div className='testimonial'>
              <div className='testimonial-content'>
                <p>
                  <em>
                    Nous tenions absolument à prendre le temps de vous écrire
                    pour vous exprimer notre entière satisfaction sur la façon
                    dont vos équipes ont géré la situation de ma maman. Nous
                    avons été globalement impressionnés par l'approche de
                    l'ensemble du personnel soignant et administratif de votre
                    établissement. C'était un bonheur d'observer leur
                    dévouement.
                  </em>
                </p>
                <span>Pierre-Alain M, Famille,</span>
                <span>Korian Roger Salengro</span>
              </div>
            </div>
            <div className='testimonial'>
              <div className='testimonial-content'>
                <p>
                  <em>
                    Nous ne pourrons jamais assez vous remercier pour tous les
                    beaux moments que mon mari a vécu grâce à vous pendant ces
                    presque quatre années. Nous, filles et épouse, vous
                    exprimons notre profonde gratitude pour l'appréciation et
                    l'affection qu'il a reçues de vous. Ce sont des cadeaux
                    inestimables.
                  </em>
                </p>
                <span>Sylviane U, Famille</span>
                <span>Korian Château des Ollières</span>
              </div>
            </div>
            <div className='testimonial'>
              <div className='testimonial-content'>
                <p>
                  <em>
                    Nous sommes enchantées d'avoir participé à cette journée
                    bien familiale et si joyeuse. Pour tout ce que vous avez
                    déployé à la réussite parfaite de cet instant MERCI. Merci à
                    tous ceux qui veillent sur nous apportant bonne humeur et
                    gaité et les compétences : trois dames de la direction,
                    responsables de l'infirmerie dont Anthony, Estelle. A toutes
                    celles, qui inlassablement compensent nos défaillances.
                  </em>
                </p>
                <span>Paulette M, Résident</span>
                <span>Korian Sarmatia</span>
              </div>
            </div>
            <div className='testimonial'>
              <div className='testimonial-content'>
                <p>
                  <em>
                    Merci à l'ensemble du personnel de Korian Lo Solehl à
                    Béziers qui fait preuve d'une remarquable gentillesse, d'une
                    grande disponibilité, d'une chaleureuse bienveillance, d'une
                    écoute attentive, d'une présence rassurante et d'un respect
                    sincère auprès des résidents et aussi des familles
                    accompagnantes (avec une mention très spéciale à Carole et
                    Virginie, l'infirmière). Exceptionnel, vraiment !
                  </em>
                </p>
                <span>Christian L, Famille</span>
                <span>Korian Lo Solelh</span>
              </div>
            </div>
            <div className='testimonial'>
              <div className='testimonial-content'>
                <p>
                  <em>
                    Nous tenons à vous remercier pour votre professionnalisme et
                    votre bienveillance envers notre mère. Depuis son arrivée
                    dans votre établissement elle est de nouveau épanouie et à
                    le sourire. Que ce soit médecin, personnel soignant kiné,
                    secrétaire, tous sont à l'écoute et disponible pour les
                    patients. Je recommande l’établissement les yeux fermés.
                  </em>
                </p>
                <span>Sahra B, Famille</span>
                <span>Korian Le Pont</span>
              </div>
            </div>
          </Carousel>
        </div>
        <div className='image-testimonial'>
          <img srcSet={bg_webp} src={bg} alt='img-bg--testimonials' />
          <div className='bx-footer--color flex'>
            <span className='fst-footer--color'></span>
            <span className='snd-footer--color'></span>
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonials;
