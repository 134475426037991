import React, { Component } from 'react';
import logo_korian from '../assets/logo.png';

// COMPONENTS
import { FaSearch } from 'react-icons/fa';
import Tooltip from '@material-ui/core/Tooltip';
import {
  iconCenter,
  iconDefault,
  iconPosition,
} from './../markers/customIcons';
import Button from '@material-ui/core/Button';
import Control from 'react-leaflet-control';
import { MdMyLocation, MdClose, MdLocationOn } from 'react-icons/md';
import { FaGlobeEurope } from 'react-icons/fa';
import Skeleton from '@material-ui/lab/Skeleton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import { Map, TileLayer, Marker, Popup, ZoomControl } from 'react-leaflet';
import Fab from '@material-ui/core/Fab';
import { CircularProgress } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { DebounceInput } from 'react-debounce-input';
import CustomRequest from '../CustomRequest';
require('react-leaflet-markercluster/dist/styles.min.css');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const closeMobileStyle = {
  position: 'relative',
  right: '5px',
  background: '#02405d',
  color: 'white',
  padding: '5px',
  borderRadius: '15px',
};

const ListComponent = ({ items, self, device, customRef }) => {
  const LOADING = 1;
  const LOADED = 2;
  let itemStatusMap = {};

  const isItemLoaded = (index) => !!itemStatusMap[index];
  const loadMoreItems = (startIndex, stopIndex) => {
    for (let index = startIndex; index <= stopIndex; index++) {
      if (index < 17) {
        itemStatusMap[index] = LOADED;
      } else {
        itemStatusMap[index] = LOADING;
      }
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        for (let index = startIndex; index <= stopIndex; index++) {
          itemStatusMap[index] = LOADED;
        }

        resolve();
      }, 200);
    });
  };

  const desktopContent = ({ index, style }) => {
    style.width = '94%';
    style.position = 'absolute';
    style.height = '125px';
    style = {
      ...style,
    };
    return (
      <div
        key={index}
        style={style}
        className={
          items[index].isSelected && items[index].focus
            ? 'preview-res--card active p-l-25'
            : 'preview-res--card p-l-25'
        }
        id={'korian-' + items[index].id}
      >
        <picture>
          <source
            srcSet={items[index].photo + '.webp'}
            className='preview--img'
            type='image/webp'
            onClick={() => {
              self.showDetailEhpad(items[index].url);
            }}
          />
          <source
            srcSet={items[index].photo}
            className='preview--img'
            type='image/jpeg'
            onClick={() => {
              self.showDetailEhpad(items[index].url);
            }}
          />
          <img
            src={items[index].photo}
            className='preview--img'
            alt={'Korian-' + items[index].name}
            onClick={() => {
              self.showDetailEhpad(items[index].url);
            }}
          />
        </picture>
        <div
          className='preview-res--infos'
          onClick={() => {
            self.showDetailEhpad(items[index].url);
          }}
        >
          <div className='res-header--bx'>
            <div className='res-header--lft'>
              <h3 className='res--name'>{items[index].name}</h3>
              <span className='res--address'>
                {items[index].department.name} ({items[index].department.code})
                | {items[index].address}, {items[index].zipcode}{' '}
                {items[index].city}
              </span>
              <span className='res--price'>
                {items[index].roomPriceS !== null
                  ? `À partir de
                ${Math.round(parseInt(items[index].roomPriceS), 2)} €
                TTC/jour (Chambre simple)`
                  : false}
              </span>
            </div>
          </div>

          {items[index].plus1 !== '' ? (
            <ul className='res--advantages'>
              <li>{items[index].plus1}</li>
              <li>{items[index].plus2}</li>
              <li>{items[index].plus3}</li>
            </ul>
          ) : (
            false
          )}
        </div>

        <div
          className='res--marker'
          onClick={() => {
            let selectedMarker;
            self.setState({
              ...self.state,
              remoteMarkers: self.state.remoteMarkers.filter((e) => {
                // vérifions si d'autres element n'avaient pas été selectionnés
                if (
                  parseFloat(e.latitude) ===
                    parseFloat(items[index].latitude) &&
                  parseFloat(e.longitude) === parseFloat(items[index].longitude)
                ) {
                  e.isSelected = true;
                  e.focus = true;
                } else {
                  e.isSelected = false;
                  e.focus = false;
                }
                return e;
              }),
              dataToShow: self.state.dataToShow.filter((marker) => {
                if (
                  parseFloat(marker.latitude) ===
                    parseFloat(items[index].latitude) &&
                  parseFloat(marker.longitude) ===
                    parseFloat(items[index].longitude)
                ) {
                  marker.isSelected = true;
                  marker.focus = true;
                  selectedMarker = marker;
                } else {
                  marker.isSelected = false;
                  marker.focus = false;
                }
                return marker;
              }),
              selectedMarker: selectedMarker,
            });
            // le setTimeout permet d'appliquer le Js sur le marker
            setTimeout(() => {
              let iconSelected = document.querySelector(
                '.leaflet-div-icon.orange'
              );
              if (iconSelected !== null) {
                iconSelected.style.zIndex = 1000;
              }
            }, 50);
          }}
        >
          <MdLocationOn className='result--icon' />
          <small>carte</small>
        </div>
      </div>
    );
  };

  const mobileContent = ({ index, style }) => {
    style.position = 'absolute';
    style.width = '188px';
    style = {
      ...style,
    };
    return (
      <div
        key={index + '-mobile'}
        style={style}
        className={
          items[index].isSelected && items[index].focus
            ? 'preview-res--card active p-l-25'
            : 'preview-res--card p-l-25'
        }
        id={'korian-' + items[index].id}
        onClick={() => {
          self.showDetailEhpad(items[index].url);
          let selectedEtab;
          setTimeout(() => {
            self.setState({
              ...self.state,
              dataForMobile: self.state.dataForMobile.filter((marker) => {
                if (
                  parseFloat(marker.latitude) ===
                    parseFloat(items[index].latitude) &&
                  parseFloat(marker.longitude) ===
                    parseFloat(items[index].longitude)
                ) {
                  marker.isSelected = true;
                  marker.focus = true;
                  selectedEtab = marker;
                } else {
                  marker.isSelected = false;
                  marker.focus = false;
                }
                return marker;
              }),
              selectedMarker: selectedEtab,
            });
          }, 100);
        }}
      >
        <picture>
          <source
            srcSet={items[index].photo + '.webp'}
            className='preview--img'
            type='image/webp'
            onClick={() => {
              self.showDetailEhpad(items[index].url);
            }}
          />
          <source
            srcSet={items[index].photo}
            className='preview--img'
            type='image/jpeg'
            onClick={() => {
              self.showDetailEhpad(items[index].url);
            }}
          />
          <img
            src={items[index].photo}
            className='preview--img'
            alt={'Korian-' + items[index].name}
            onClick={() => {
              self.showDetailEhpad(items[index].url);
            }}
          />
        </picture>
        <div className='preview-res--infos'>
          <div className='res-header--bx'>
            <div className='res-header--lft'>
              <h3 className='res--name'>{items[index].name}</h3>
              <span className='res--address'>
                {items[index].department.name} ({items[index].department.code})
                | {items[index].address}, {items[index].zipcode}{' '}
                {items[index].city}
              </span>
              <span className='res--price'>
                {items[index].roomPriceS !== null
                  ? `À partir de
                ${Math.round(parseInt(items[index].roomPriceS), 2)} €
                TTC/jour (Chambre simple)`
                  : false}
              </span>
            </div>
          </div>
          {items[index].plus1 !== '' ? (
            <ul className='res--advantages'>
              <li>{items[index].plus1}</li>
              <li>{items[index].plus2}</li>
              <li>{items[index].plus3}</li>
            </ul>
          ) : (
            false
          )}
        </div>
      </div>
    );
  };

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={items.length}
      loadMoreItems={loadMoreItems}
    >
      {device === 'desktop'
        ? ({ onItemsRendered, ref }) => (
            <FixedSizeList
              className='lazyBox'
              height={470}
              width={639}
              itemCount={items.length}
              itemSize={161}
              onItemsRendered={onItemsRendered}
              ref={customRef}
            >
              {desktopContent}
            </FixedSizeList>
          )
        : ({ onItemsRendered, ref }) => (
            <FixedSizeList
              className='lazyBox'
              height={470}
              width={550}
              itemCount={items.length}
              itemSize={210}
              style={{
                width: '100% important',
                overflowY: 'inherit',
              }}
              layout='horizontal'
              onItemsRendered={onItemsRendered}
              ref={customRef}
            >
              {mobileContent}
            </FixedSizeList>
          )}
    </InfiniteLoader>
  );
};

class MapBlock extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.source = 'places?autocomplete=true&groups[]=place_front';
    this.source_exact = 'places/exact';
    this.entity = 'place';
    this.initPosition = [46.8732324, 2.0106608];
    this.initZoom = 5;
    this.markerZoom = 12;
    this.depZoom = 9;
    this.cityPos = 12;
    this.regZoom = 8;
    this.myPositionZoom = 12;
    this.centerRef = React.createRef();
    this.listRef = React.createRef();
    this.dataIsLoaded = false;
    this.state = {
      moreItemsLoading: false,
      hasNextPage: true,
      remoteMarkers: [],
      position: [46.8732324, 2.0106608],
      zoom: 6,
      center: null,
      loading: false,
      dataForMobile: [],
      selectedMarker: null,
      filterByName: false,
      filterByDistance: true,
      selectedData: {},
      selectedCity: null,
      dataToShow: [],
      open: false,
      openMapMobile: false,
      showList: false,
      showPosition: {
        show: false,
        position: [],
      },
      searchText: '',
      searchResultat: [],
      options: {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
    };
  }

  handleChange(event) {
    let value = event.target.value;
    if (value === '') {
      // Déclenche le loader
      this.setState({
        ...this.state,
        loading: true,
        searchResultat: [],
      });
      // Stop le loader 800 ms après
      setTimeout(() => {
        this.setState({
          ...this.state,
          loading: false,
          selectedCity: null,
          searchResultat: [],
        });
      }, 800);
    }
    // Déclenche le loader
    this.setState({
      ...this.state,
      loading: true,
      searchResultat: [],
    });
    let url = this.source + '&searchName=' + value;
    let customHeader = {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };
    if (value !== undefined && value.length >= 2) {
      let db_request = CustomRequest(url,
        customHeader
      );
      // launchRequest.then(() => {
      fetch(db_request)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          let places = data['hydra:member'];
          let places_to_show = [];
          places.map((place) => {
            places_to_show.push({
              id: place.id,
              name: place.name,
              latitude: place.latitude,
              longitude: place.longitude,
              code: place.code,
              zipcode: place.zipcode,
              type: place.type,
              mapZoom: place.map_zoom,
            });
          });
          this.setState({
            ...this.state,
            showList: true,
            loading: false,
            searchResultat: places_to_show,
          });
        });
    }
  }

  checkInsideTheCluster(position) {
    let leafletElement = this.centerRef.current.leafletElement;
    let cluster = null;

    // Parcourons la liste des layers sur la map et récupérons ceux présents
    // à l'intérieur de la div (map)
    // console.log(
    //   L.markerClusterGroup({
    //     iconCreateFunction: function(cluster) {
    //       console.log(cluster);
    //       return L.divIcon({ html: '<b>' + cluster.getChildCount() + '</b>' });
    //     }
    //   })
    // );
    leafletElement.eachLayer((layer) => {
      if (typeof layer.getAllChildMarkers === 'function') {
        let layerList = layer.getAllChildMarkers();
        let found = false;
        layerList.map((el) => {
          let elPosition = el.getLatLng();
          if (
            parseFloat(elPosition.lat) === parseFloat(position.lat) &&
            parseFloat(elPosition.lng) === parseFloat(position.lng)
          ) {
            found = true;
          }
        });
        if (found) {
          cluster = layer;
        }
      }
    });
    //Vérifions si nous avons un cluster
    if (cluster !== null) {
      // Récupérons le contenu HTML du cluster
      // clusterHtml = div.leaflet-marker-icon.marker-cluster-custom
      let clusterHtml = cluster.createIcon();
      // img du cluster se trouve dans la
      // div.leaflet-marker-icon.marker-cluster-custom > div.cluster--box > img
      let img = document.querySelector(
        '.leaflet-marker-icon.marker-cluster-custom > .cluster--box > img'
      );
      // let img = clusterHtml.children[0].children[0];
      // console.log(clusterHtml);
      // console.log(img);
      img.setAttribute('src', ''); //IconSelectedCluster
    }
  }

  // Function use to show markers inside the map
  getLayerInsideMap(element) {
    this.dataIsLoaded = false;
    let leafletElement = element;
    let center = leafletElement.getCenter();
    let bounds = leafletElement.getBounds();
    let insideLayer = [];
    // Parcourons la liste des layers sur la map et récupérons ceux présents
    // à l'intérieur de la div (map)
    leafletElement.eachLayer((layer) => {
      if (layer['_url'] === undefined) {
        if (typeof layer.getLatLng === 'function') {
          if (bounds.contains(layer.getLatLng())) {
            insideLayer.push(layer.getLatLng());
          }
        }
      }

      if (typeof layer.getAllChildMarkers === 'function') {
        let layerList = layer.getAllChildMarkers();
        layerList.map((el) => {
          if (bounds.contains(el.getLatLng())) {
            insideLayer.push(el.getLatLng());
          }
        });
      }
    });

    // Retirer les doublons
    insideLayer = insideLayer.filter(
      (layer, index, self) =>
        index ===
        self.findIndex((t) => t.lat === layer.lat && t.lng === layer.lng)
    );
    let dataToShow = [];

    insideLayer.map((layerInfo) => {
      this.state.remoteMarkers.filter((e) => {
        if (
          parseFloat(e.latitude) === layerInfo.lat &&
          parseFloat(e.longitude) === layerInfo.lng
        ) {
          dataToShow.push(e);
        }
      });
    });

    this.dataIsLoaded = true;
    this.setState({
      ...this.state,
      center: center,
      dataToShow: dataToShow,
      dataForMobile: dataToShow,
    });

    this.filterByDistance();
  }

  getAllData() {
    this.dataIsLoaded = true;
    this.setState({
      ...this.state,
      dataForMobile: this.state.remoteMarkers,
    });
  }

  selectedLayerInsideMap(element) {
    this.dataIsLoaded = false;
    let leafletElement = element;
    let center = leafletElement.getCenter();
    let bounds = leafletElement.getBounds();
    let insideLayer = [];
    // Parcourons la liste des layers sur la map et récupérons ceux présents
    // à l'intérieur de la div (map)
    leafletElement.eachLayer((layer) => {
      if (layer['_url'] === undefined) {
        if (typeof layer.getLatLng === 'function') {
          if (bounds.contains(layer.getLatLng())) {
            insideLayer.push(layer.getLatLng());
          }
        }
      }

      if (typeof layer.getAllChildMarkers === 'function') {
        let layerList = layer.getAllChildMarkers();
        layerList.map((el) => {
          if (bounds.contains(el.getLatLng())) {
            insideLayer.push(el.getLatLng());
          }
        });
      }
    });

    // Retirer les doublons
    insideLayer = insideLayer.filter(
      (layer, index, self) =>
        index ===
        self.findIndex((t) => t.lat === layer.lat && t.lng === layer.lng)
    );
    let dataToShow = [];

    insideLayer.map((layerInfo) => {
      this.state.remoteMarkers.filter((e) => {
        if (
          parseFloat(e.latitude) === layerInfo.lat &&
          parseFloat(e.longitude) === layerInfo.lng
        ) {
          dataToShow.push(e);
        }
      });
    });

    this.dataIsLoaded = true;
    this.setState({
      ...this.state,
      center: center,
      dataToShow: dataToShow,
      dataForMobile: dataToShow,
    });

    this.filterByDistance();
  }

  success(pos) {
    let crd = pos.coords;
    this.setState({
      ...this.state,
      showPosition: {
        show: true,
        position: [crd.latitude, crd.longitude],
      },
      center: { lat: crd.latitude, lng: crd.longitude },
    });
    this.flyTo(this.state.center, this.myPositionZoom);
  }

  error(err) {
    console.error(`ERREUR (${err.code}): ${err.message}`);
  }

  getClientPosition() {
    if ('geolocation' in navigator) {
      /* la géolocalisation est disponible */
      navigator.geolocation.getCurrentPosition(
        this.success.bind(this),
        this.error.bind(this),
        this.state.options
      );
    } else {
      /* la géolocalisation n'est pas disponible */
      console.log('Ce navigateur ne permet pas de vous localiser');
    }
  }

  filterByName() {
    this.setState((prevstate) => ({
      ...prevstate,
      dataToShow: prevstate.dataToShow.sort((a, b) => {
        if (prevstate.filterByName === false) {
          if (a.name > b.name) {
            return -1;
          }
          if (a.name < b.name) {
            return 1;
          }
          return 0;
        } else {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }
      }),
      filterByName: !prevstate.filterByName,
    }));
  }

  filterByDistance() {
    let leafletElement =
      this.centerRef.current !== null
        ? this.centerRef.current.leafletElement
        : null;
    let center = this.state.center;
    if (leafletElement !== null) {
      this.setState((prevstate) => ({
        ...prevstate,
        dataToShow: prevstate.dataToShow.sort((a, b) => {
          let positionA = { lat: a.latitude, lng: a.longitude };
          let positionB = { lat: b.latitude, lng: b.longitude };
          // Croissant
          if (
            leafletElement.distance(center, positionA) <
            leafletElement.distance(center, positionB)
          ) {
            return -1;
          }
          if (
            leafletElement.distance(center, positionA) >
            leafletElement.distance(center, positionB)
          ) {
            return 1;
          }
          return 0;
          // }
        }),
        filterByDistance: !prevstate.filterByDistance,
      }));
    }
  }

  checkUrl() {
    if (this.props.formType === 'short') {
      let pathname = document.location.pathname.split('/')[1];
      let research = pathname === '' ? null : pathname;
      if (research !== null) {
        let url = this.source_exact + '/' + research;
        let customHeader = {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        };
        let db_request = CustomRequest(url,
          customHeader
        );
        fetch(db_request)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (data.id) {
              // Update the parent place
              this.props.setPlace(data.name);
              this.flyTo([data.latitude, data.longitude], data.mapZoom);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else {
      // Condition du formulaire long
      let pathname = document.location.pathname.split('/')[1];
      let secondArg = document.location.pathname.split('/')[2];
      let research =
        pathname === 'formulaire-long-v2' && secondArg !== undefined
          ? secondArg
          : null;
      if (research !== null) {
        let url = this.source_exact + '/' + research;
        let customHeader = {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        };
        let db_request = CustomRequest(url,
          customHeader
        );
        fetch(db_request)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (data.id) {
              // Update the parent place
              this.props.setPlace(data.name);
              this.flyTo([data.latitude, data.longitude], data.mapZoom);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  }

  componentWillMount() {
    this.checkUrl();
    let url =
      'facilities?groups[]=facility_front&isDeleted=0';
    let customHeader = {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };

    let request = CustomRequest(url, customHeader);

    // timeout to let the component loading before adding data
    fetch(request)
      .then((r) => {
        r.json()
          .then((data) => {
            this.setState({
              ...this.state,
              remoteMarkers: data['hydra:member'].filter((e) => {
                if (e.type.code === 'F001T') {
                  e.isSelected = false;
                  e.focus = false;
                  return e;
                }
              }),
            });
            let leafletElement = this.centerRef.current.leafletElement;
            this.getLayerInsideMap(leafletElement);
            this.getAllData();
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  componentDidMount() {
    // au déplacement du scroll
    window.addEventListener('scroll', (e) => {
      if (this.state.showList) {
        let box = document.querySelector('#villes.search-results');
        if (window.pageYOffset < 373) {
          if (box !== null && window.screen.width > 1024) {
            box.setAttribute('style', 'margin-top: -353px;');
          }
        } else {
          if (box !== null) {
            box.setAttribute('style', 'margin-top: 1px;');
          }
        }
      }
    });
  }

  flyTo(position, zoom) {
    // Vérifions dans quel environnement nous évoluons
    let leaflet = document.querySelector('.leaflet-container');
    if (leaflet.offsetParent !== null) {
      // Desktop map
      this.setState({
        ...this.state,
        showList: false,
      });
      this.centerRef.current.leafletElement.flyTo(position, zoom);
      // get the element selected
      let idElement = '';
      this.state.dataToShow.map((e) => {
        let position = this.state.dataToShow.indexOf(e);
        if (e.latitude === position[0] && e.longitude === position[1]) {
          idElement = e.id;
          let element = document.querySelector('#korian-' + idElement);
          // element.scrollIntoViewIfNeeded(true);
          let parent = element ? element.parentNode : null;

          if (parent) {
            parent.scrollTo({
              top: element.offsetTop - parent.offsetTop,
              behavior: 'instant',
              block: 'start',
              inline: 'nearest',
            });
          }
        }
      });
    } else {
      this.setState({
        ...this.state,
        openMapMobile: true,
        showList: false,
      });

      setTimeout(() => {
        this.centerRef.current.leafletElement.flyTo(position, zoom);
        let idElement = '';
        this.state.dataToShow.map((e) => {
          if (e.latitude === position[0] && e.longitude === position[1]) {
            idElement = e.id;
            let element = document.querySelector('#korian-' + idElement);
            // element.scrollIntoViewIfNeeded(true);
            let parent = element !== null ? element.parentNode : null;
            if (parent) {
              parent.scrollTo({
                top: element.offsetTop - parent.offsetTop,
                behavior: 'instant',
                block: 'start',
                inline: 'nearest',
              });
            }
          }
        });
      }, 800);
    }
  }

  showMobileMap() {
    let city = this.state.selectedCity;
    let selectedEtab = this.state.selectedMarker;
    if (city !== null) {
      this.flyTo([city.latitude, city.longitude], city.mapZoom);
    }
    if (selectedEtab !== null) {
      this.flyTo(
        [selectedEtab.latitude, selectedEtab.longitude],
        selectedEtab.mapZoom
      );
    }
  }

  focusElementOnHover(value) {
    let selectedMarker = null;
    let position = null;
    this.setState((prev) => ({
      ...prev,
      remoteMarkers: prev.remoteMarkers.filter((e) => {
        // vérifions si d'autres element n'avaient pas été selectionnés
        if (
          parseFloat(e.latitude) !== parseFloat(value.latitude) &&
          parseFloat(e.longitude) !== parseFloat(value.longitude)
        ) {
          e.isSelected = false;
          e.focus = false;
        }
        return e;
      }),
      dataToShow: prev.dataToShow.filter((marker) => {
        if (
          parseFloat(marker.latitude) === parseFloat(value.latitude) &&
          parseFloat(marker.longitude) === parseFloat(value.longitude)
        ) {
          marker.isSelected = true;
          marker.focus = true;
          selectedMarker = marker;
          position = prev.dataToShow.indexOf(marker);
        } else {
          marker.isSelected = false;
          marker.focus = false;
        }
        return marker;
      }),
      dataForMobile: prev.dataForMobile.filter((marker) => {
        if (
          parseFloat(marker.latitude) === parseFloat(value.latitude) &&
          parseFloat(marker.longitude) === parseFloat(value.longitude)
        ) {
          marker.isSelected = true;
          marker.focus = true;
          selectedMarker = marker;
          position = prev.dataToShow.indexOf(marker);
        } else {
          marker.isSelected = false;
          marker.focus = false;
        }
        return marker;
      }),
      selectedMarker: selectedMarker,
    }));
    // get the element selected
    let idElement = '';
    this.state.dataToShow.map((e) => {
      if (e.latitude === value.latitude && e.longitude === value.longitude) {
        idElement = e.id;
        let element = document.querySelector('#korian-' + idElement);
        let parent = document.querySelector('.resultats--list');
        if (
          !navigator.userAgent.match(/Trident\/7\./) &&
          window.navigator.userAgent.indexOf('Edge') === -1 &&
          element !== null &&
          parent !== null
        ) {
          parent.scrollTo({
            top: element.offsetTop - parent.offsetTop,
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        }

        if (
          !navigator.userAgent.match(/Trident\/7\./) &&
          window.navigator.userAgent.indexOf('Edge') === -1 &&
          (element === null || element !== null || parent === null)
        ) {
          this.listRef.current.scrollToItem(position);
        }

        // element.scrollIntoViewIfNeeded(true); // retourne le scroll à la positiion précise de l'élément
      }
    });
  }

  focusElementOnClick(value) {
    let selectedMarker = null;
    this.setState((prev) => ({
      ...prev,
      remoteMarkers: prev.remoteMarkers.filter((e) => {
        // vérifions si d'autres element n'avaient pas été selectionnés
        if (
          parseFloat(e.latitude) !== parseFloat(value.latitude) &&
          parseFloat(e.longitude) !== parseFloat(value.longitude)
        ) {
          e.isSelected = false;
          e.focus = false;
        }
        return e;
      }),
      dataToShow: prev.dataToShow.filter((marker) => {
        if (
          parseFloat(marker.latitude) === parseFloat(value.latitude) &&
          parseFloat(marker.longitude) === parseFloat(value.longitude)
        ) {
          marker.isSelected = true;
          marker.focus = true;
        } else {
          marker.isSelected = false;
          marker.focus = false;
        }
        return marker;
      }),
      selectedMarker: selectedMarker,
    }));
    this.checkInsideTheCluster({ lat: value.latitude, lng: value.longitude });
  }

  showDetailEhpad(url, self = this) {
    let newUrl = 'https://www.korian.fr/' + url + '?mode=iframe';
    self.setState({
      ...this.state,
      selectedData: {
        url: newUrl,
      },
      open: !self.state.open,
    });
  }

  gotoFrance() {
    this.flyTo(this.initPosition, this.state.zoom);
  }

  closeMarkerDetail() {
    this.setState({
      ...this.state,
      remoteMarkers: this.state.remoteMarkers.filter((e) => {
        e.isSelected = false;
        e.focus = false;
        return e;
      }),
      dataToShow: this.state.dataToShow.filter((marker) => {
        marker.isSelected = false;
        marker.focus = false;
        return marker;
      }),
      selectedMarker: null,
    });
  }

  showNumberResultat() {
    const { dataToShow, dataForMobile } = this.state;
    // Condition d'affichage en fonction du device
    let mobileContent = document.querySelector('.resultats--list--mobile');
    if (mobileContent === null) {
      // Mode desktop
      return dataToShow.length > 0
        ? dataToShow.length + ' Résultat(s) :'
        : '0 Résultat(s) :';
    } else {
      // Mode mobile
      return dataForMobile.length > 0
        ? dataForMobile.length + ' Résultat(s) :'
        : '0 Résultat(s) :';
    }
  }

  render() {
    const {
      zoom,
      position,
      dataToShow,
      dataForMobile,
      open,
      openMapMobile,
      remoteMarkers,
    } = this.state;

    const { searchPlace } = this.props;

    return (
      <div className='map--bx'>
        <div className='left--bx'>
          <div className='header-title-mobile'>
            <h3>
              Recherchez votre maison de retraite Korian{' '}
              {searchPlace !== null ? `: ${searchPlace}` : false}
            </h3>
          </div>
          <h2 className='title--header p-l-25'>Votre recherche</h2>

          <div className='form-control w-100 p-l-25 flex'>
            <div className='search-city--bx'>
              <FaSearch className='search--icon' />
              <DebounceInput
                minLength={2}
                debounceTimeout={300}
                id='city'
                className='text--input'
                name='city'
                type='text'
                value={this.state.searchText}
                autoComplete='off'
                onChange={(e) => {
                  this.handleChange(e);
                }}
                onClick={(e) => {
                  if (this.state.searchResultat.length !== 0) {
                    this.setState({
                      ...this.state,
                      showList: true,
                    });
                  }
                }}
                onBlur={(e) => {
                  setTimeout(() => {
                    this.setState({
                      ...this.state,
                      showList: false,
                    });
                  }, 300);
                }}
              />
              <ul
                id='villes'
                className='search-results'
                style={
                  this.state.showList
                    ? { display: 'block' }
                    : { display: 'none' }
                }
              >
                {this.state.searchResultat.map((element, index) => (
                  <li
                    key={index}
                    onClick={(e) => {
                      this.flyTo(
                        [element.latitude, element.longitude],
                        element.mapZoom
                      );
                      setTimeout(() => {
                        this.setState({
                          ...this.state,
                          selectedCity: element,
                          searchText: element.name,
                        });
                      }, 1000);
                    }}
                  >
                    <MdLocationOn className='result--icon' />
                    {element.type === 'Ville'
                      ? `${element.name} (${element.zipcode})`
                      : `${element.name} (${element.code})`}
                  </li>
                ))}
              </ul>
            </div>
            <div className='text--input-validation'>
              <MdMyLocation
                className='search-location-icon'
                onClick={() => {
                  this.getClientPosition();
                }}
              />
              {this.state.loading ? (
                <CircularProgress
                  color='inherit'
                  size={20}
                  className='progress--icon'
                />
              ) : (
                false
              )}
            </div>
          </div>
          <h2 className='title--resultat p-l-25'>
            {this.dataIsLoaded ? this.showNumberResultat() : 'Chargement...'}
          </h2>
          <div className='tabs--mobile--bx'>
            <ButtonGroup
              variant='contained'
              color='primary'
              aria-label='contained primary button group'
            >
              <Button className='tab--map'>Liste</Button>
              <Button
                className='tab--list'
                onClick={() => {
                  this.showMobileMap();
                  this.setState({
                    ...this.state,
                    openMapMobile: true,
                  });
                }}
              >
                Carte
              </Button>
            </ButtonGroup>
          </div>

          {/* Affichage desktop */}
          {window.screen.width > 1024 ? (
            <div className='resultats--list'>
              {this.dataIsLoaded ? (
                <ListComponent
                  items={dataToShow}
                  self={this}
                  customRef={this.listRef}
                  device={'desktop'}
                />
              ) : (
                [1, 2, 3].map((value) => (
                  <div key={value} className='preview-res--card p-l-25'>
                    <Skeleton
                      variant='circle'
                      animation='wave'
                      width={250}
                      height={110}
                      style={{ borderRadius: '10px' }}
                    />
                    <div className='preview-res--infos'>
                      <div className='res-header--bx'>
                        <div className='res-header--lft'>
                          <h3 className='res--name'>
                            <Skeleton
                              animation='wave'
                              width={350}
                              height={15}
                            />
                          </h3>
                          <span className='res--address'>
                            <Skeleton
                              animation='wave'
                              width={350}
                              height={12}
                            />
                          </span>
                          <span className='res--price'>
                            <Skeleton
                              animation='wave'
                              width={350}
                              height={10}
                            />
                          </span>
                        </div>
                        <Skeleton animation='wave' width={15} height={10} />
                      </div>
                      <ul className='res--advantages'>
                        <span>
                          <Skeleton
                            animation='wave'
                            width={250}
                            style={{ margin: '2px' }}
                          />
                        </span>
                        <span>
                          <Skeleton
                            animation='wave'
                            width={250}
                            style={{ margin: '2px' }}
                          />
                        </span>
                        <span>
                          <Skeleton
                            animation='wave'
                            width={250}
                            style={{ margin: '2px' }}
                          />
                        </span>
                      </ul>
                    </div>
                  </div>
                ))
              )}
            </div>
          ) : (
            false
          )}

          {/* Affichage mobile */}
          {window.screen.width <= 1024 ? (
            <div className='resultats--list--mobile'>
              {this.dataIsLoaded ? (
                <ListComponent
                  items={dataForMobile}
                  self={this}
                  customRef={this.listRef}
                  device={'mobile'}
                />
              ) : (
                [1, 2, 3, 4].map((value) => (
                  <div key={value} className='preview-res--card p-l-25'>
                    <Skeleton
                      variant='circle'
                      animation='wave'
                      width={250}
                      height={110}
                      style={{ borderRadius: '10px' }}
                    />
                    <div className='preview-res--infos'>
                      <div className='res-header--bx'>
                        <div className='res-header--lft'>
                          <h3 className='res--name'>
                            <Skeleton
                              animation='wave'
                              width={200}
                              height={15}
                            />
                          </h3>
                          <span className='res--address'>
                            <Skeleton
                              animation='wave'
                              width={200}
                              height={12}
                            />
                          </span>
                          <span className='res--price'>
                            <Skeleton
                              animation='wave'
                              width={200}
                              height={10}
                            />
                          </span>
                        </div>
                      </div>
                      <ul className='res--advantages'>
                        <span>
                          <Skeleton
                            animation='wave'
                            width={150}
                            style={{ margin: '2px' }}
                          />
                        </span>
                        <span>
                          <Skeleton
                            animation='wave'
                            width={150}
                            style={{ margin: '2px' }}
                          />
                        </span>
                        <span>
                          <Skeleton
                            animation='wave'
                            width={150}
                            style={{ margin: '2px' }}
                          />
                        </span>
                      </ul>
                    </div>
                  </div>
                ))
              )}
            </div>
          ) : (
            false
          )}

          <hr className='divider' />
        </div>
        <div className='right--bx'>
          {remoteMarkers.length === 0 ? (
            <React.Fragment>
              <span className='backdrop'></span>
              <div className='loader-map'>
                <CircularProgress color='inherit' />
                <span style={{ color: 'white', marginLeft: '10px' }}>
                  Chargement ...
                </span>
              </div>
            </React.Fragment>
          ) : (
            false
          )}
          <Map
            center={position}
            ref={this.centerRef}
            zoomControl={false}
            onMoveend={(event) => {
              let leafletElement = event.target;
              this.selectedLayerInsideMap(leafletElement);
              let iconSelected = document.querySelector(
                '.leaflet-div-icon.orange'
              );
              if (iconSelected !== null) {
                iconSelected.style.zIndex = 1000;
                let element = this.state.dataToShow.filter((e) => {
                  if (e.focus) {
                    return e;
                  }
                });
                let position = this.state.dataToShow.indexOf(element[0]);
                this.listRef.current.scrollToItem(position);
              }
            }}
            animate={true}
            // easeLinearity={0.35}
            zoom={zoom}
            maxZoom={13}
          >
            <TileLayer url='https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png' />

            {/* <MarkerClusterGroup
              animate={true}
              maxClusterRadius={25}
              showCoverageOnHover={false}
              iconCreateFunction={clusterDefaultIcon}
            > */}
            {remoteMarkers.map((marker) => (
              <Marker
                key={marker.id}
                position={[marker.latitude, marker.longitude]}
                icon={marker.isSelected ? iconCenter : iconDefault}
                onClick={(e) => {
                  this.focusElementOnHover(marker);
                  let leaflet = this.centerRef.current.leafletElement;
                  if (leaflet.getZoom() < marker.mapZoom) {
                    this.flyTo(
                      [marker.latitude, marker.longitude],
                      marker.mapZoom
                    );
                  }
                }}
              >
                {navigator.userAgent.match(/Trident\/7\./) ||
                window.navigator.userAgent.indexOf('Edge') > -1 ? (
                  <Popup>
                    {marker.name} <br /> {marker.address}
                  </Popup>
                ) : (
                  false
                )}
              </Marker>
            ))}
            {this.state.showPosition.show ? (
              <Tooltip
                title='Vous êtes ici'
                aria-label='my_position'
                placement='top'
              >
                <Marker
                  position={this.state.showPosition.position}
                  icon={iconPosition}
                ></Marker>
              </Tooltip>
            ) : (
              false
            )}
            {/* </MarkerClusterGroup> */}
            <ZoomControl position='topright' />
            <Control position='bottomright'>
              <Fab
                className='location--icon'
                aria-label='position'
                small='true'
                onClick={() => {
                  this.getClientPosition();
                }}
              >
                <MdMyLocation />
              </Fab>
            </Control>
            <Control position='bottomleft'>
              <Fab
                className='location--icon'
                aria-label='position'
                small='true'
                onClick={() => {
                  this.gotoFrance();
                }}
              >
                <FaGlobeEurope />
              </Fab>
            </Control>
          </Map>
        </div>
        {/* IFRAME */}
        <Dialog
          fullScreen
          open={open}
          onClose={() => {
            this.setState({
              ...this.state,
              open: !open,
            });
          }}
          TransitionComponent={Transition}
        >
          <AppBar className='appbar-header--details' elevation={0}>
            <Toolbar className='header--details'>
              <Button color='inherit'>{''}</Button>
              <div className='box--logo'>
                <img src={logo_korian} alt='logo korian' />
                <Button
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      open: !open,
                      openMapMobile: false,
                    });
                    setTimeout(() => {
                      // jump to form
                      let element = document.querySelector('#header');
                      let parent = window;
                      parent.scrollTo({
                        top: element.offsetTop,
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'nearest',
                      });
                    }, 300);
                  }}
                >
                  Contacter l'établissement
                </Button>
              </div>
              <IconButton
                edge='start'
                className='btn--close'
                onClick={() => {
                  this.setState({
                    ...this.state,
                    open: !open,
                  });
                }}
                aria-label='close'
              >
                <MdClose />
              </IconButton>
            </Toolbar>
          </AppBar>
          <iframe
            id='ehpad-details'
            width='99.8%'
            height='100%'
            className='iframe--details'
            src={this.state.selectedData.url}
          ></iframe>
        </Dialog>

        {/* MAP MOBILE */}
        <Dialog
          fullScreen
          open={openMapMobile}
          onClose={() => {
            this.setState({
              ...this.state,
              openMapMobile: !openMapMobile,
            });
          }}
          TransitionComponent={Transition}
        >
          {remoteMarkers.length > 0 ? (
            <Map
              center={position}
              ref={this.centerRef}
              zoomControl={false}
              onMoveend={(event) => {
                let leafletElement = event.target;
                this.selectedLayerInsideMap(leafletElement);
                setTimeout(() => {
                  let iconSelected = document.querySelectorAll(
                    '.leaflet-div-icon.orange'
                  )[1];
                  if (iconSelected !== null || iconSelected !== undefined) {
                    iconSelected.style.zIndex = 1000;
                  }
                }, 50);
              }}
              animate={true}
              // easeLinearity={0.35}
              zoom={zoom}
              maxZoom={13}
            >
              <TileLayer url='https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png' />

              {/* <MarkerClusterGroup
                animate={true}
                maxClusterRadius={25}
                showCoverageOnHover={false}
                iconCreateFunction={clusterDefaultIcon}
              > */}
              {remoteMarkers.map((marker) => (
                <Marker
                  key={marker.id}
                  position={[marker.latitude, marker.longitude]}
                  icon={marker.isSelected ? iconCenter : iconDefault}
                  onClick={(e) => {
                    this.focusElementOnHover(marker);
                    let leaflet = this.centerRef.current.leafletElement;
                    if (leaflet.getZoom() < this.markerZoom) {
                      this.flyTo(
                        [marker.latitude, marker.longitude],
                        this.markerZoom
                      );
                    }
                  }}
                ></Marker>
              ))}
              {this.state.showPosition.show ? (
                <Tooltip
                  title='Vous êtes ici'
                  aria-label='my_position'
                  placement='top'
                >
                  <Marker
                    position={this.state.showPosition.position}
                    icon={iconPosition}
                  ></Marker>
                </Tooltip>
              ) : (
                false
              )}
              {/* </MarkerClusterGroup> */}
              <Control position='bottomright'>
                <Fab
                  className='location--icon'
                  aria-label='position'
                  small='true'
                  onClick={() => {
                    this.getClientPosition();
                  }}
                >
                  <MdMyLocation />
                </Fab>
              </Control>
              <ZoomControl position='bottomright' />
              <Control position='topright'>
                <Fab
                  className='location--icon'
                  aria-label='close map'
                  small='true'
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      openMapMobile: !openMapMobile,
                    });
                  }}
                >
                  <MdClose />
                </Fab>
              </Control>

              <Control position='bottomleft'>
                <Fab
                  className='location--icon'
                  aria-label='position'
                  small='true'
                  onClick={() => {
                    this.gotoFrance();
                  }}
                >
                  <FaGlobeEurope />
                </Fab>
              </Control>
            </Map>
          ) : (
            // Before data is loaded
            false
          )}

          {/* Mobile Preview */}
          {this.state.selectedMarker !== null ? (
            <div className='mobile--preview'>
              <div
                style={{ paddingTop: '10px' }}
                className={'preview-res--card p-l-25'}
                id={'korian-' + this.state.selectedMarker.name}
              >
                <picture>
                  <source
                    srcSet={this.state.selectedMarker.photo + '.webp'}
                    className='preview--img'
                    type='image/webp'
                    onClick={() => {
                      this.showDetailEhpad(this.state.selectedMarker.url);
                    }}
                  />
                  <source
                    srcSet={this.state.selectedMarker.photo + '.webp'}
                    className='preview--img'
                    type='image/jpeg'
                    onClick={() => {
                      this.showDetailEhpad(this.state.selectedMarker.url);
                    }}
                  />
                  <img
                    src={this.state.selectedMarker.photo}
                    className='preview--img'
                    alt={'Korian-' + this.state.selectedMarker.name}
                    onClick={() => {
                      this.showDetailEhpad(this.state.selectedMarker.url);
                    }}
                  />
                </picture>
                <div
                  className='res-header--bx'
                  onClick={() => {
                    this.showDetailEhpad(this.state.selectedMarker.url);
                  }}
                >
                  <div className='res-header--lft'>
                    <h3 className='res--name'>
                      {this.state.selectedMarker.name}{' '}
                    </h3>
                    <span className='res--address'>
                      {this.state.selectedMarker.department.name} (
                      {this.state.selectedMarker.department.code}) |{' '}
                      {this.state.selectedMarker.address}, <br />
                      {this.state.selectedMarker.zipcode}{' '}
                      {this.state.selectedMarker.city}
                    </span>
                    <span className='res--price'>
                      {this.state.selectedMarker.roomPriceS !== null
                        ? `À partir de
                                  ${Math.round(
                                    parseInt(
                                      this.state.selectedMarker.roomPriceS
                                    ),
                                    2
                                  )} €
                                  TTC/jour (Chambre simple)`
                        : false}
                    </span>
                  </div>
                  <div className='preview-res--infos'>
                    <ul className='res--advantages'>
                      <li>{this.state.selectedMarker.plus1}</li>
                      <li>{this.state.selectedMarker.plus2}</li>
                      <li>{this.state.selectedMarker.plus3}</li>
                    </ul>
                  </div>
                </div>

                <MdClose
                  className='close--preview'
                  style={closeMobileStyle}
                  onClick={() => {
                    this.closeMarkerDetail();
                  }}
                />
                <div
                  className='preview-res--infos lg-format'
                  onClick={() => {
                    this.showDetailEhpad(this.state.selectedMarker.url);
                  }}
                >
                  {this.state.selectedMarker.plus1 !== '' ? (
                    <ul className='res--advantages'>
                      <li>{this.state.selectedMarker.plus1}</li>
                      <li>{this.state.selectedMarker.plus2}</li>
                      <li>{this.state.selectedMarker.plus3}</li>
                    </ul>
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          ) : (
            false
          )}
        </Dialog>
      </div>
    );
  }
}

export default MapBlock;
